/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PlantOut from './PlantOut';

/**
 * The UserSummary model module.
 * @module model/UserSummary
 * @version v2
 */
class UserSummary {
    /**
     * Constructs a new <code>UserSummary</code>.
     * @alias module:model/UserSummary
     */
    constructor() { 
        
        UserSummary.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UserSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserSummary} obj Optional instance to populate.
     * @return {module:model/UserSummary} The populated <code>UserSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserSummary();

            if (data.hasOwnProperty('userGuid')) {
                obj['userGuid'] = ApiClient.convertToType(data['userGuid'], 'String');
            }
            if (data.hasOwnProperty('lastUpload')) {
                obj['lastUpload'] = ApiClient.convertToType(data['lastUpload'], 'Date');
            }
            if (data.hasOwnProperty('plantWithMostAlarms')) {
                obj['plantWithMostAlarms'] = PlantOut.constructFromObject(data['plantWithMostAlarms']);
            }
            if (data.hasOwnProperty('openAlarms')) {
                obj['openAlarms'] = ApiClient.convertToType(data['openAlarms'], 'Number');
            }
            if (data.hasOwnProperty('activeAlarms')) {
                obj['activeAlarms'] = ApiClient.convertToType(data['activeAlarms'], 'Number');
            }
            if (data.hasOwnProperty('maintenanceBookEntries')) {
                obj['maintenanceBookEntries'] = ApiClient.convertToType(data['maintenanceBookEntries'], 'Number');
            }
            if (data.hasOwnProperty('lastMaintenanceBookEntry')) {
                obj['lastMaintenanceBookEntry'] = ApiClient.convertToType(data['lastMaintenanceBookEntry'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {String} userGuid
 */
UserSummary.prototype['userGuid'] = undefined;

/**
 * @member {Date} lastUpload
 */
UserSummary.prototype['lastUpload'] = undefined;

/**
 * @member {module:model/PlantOut} plantWithMostAlarms
 */
UserSummary.prototype['plantWithMostAlarms'] = undefined;

/**
 * @member {Number} openAlarms
 */
UserSummary.prototype['openAlarms'] = undefined;

/**
 * @member {Number} activeAlarms
 */
UserSummary.prototype['activeAlarms'] = undefined;

/**
 * @member {Number} maintenanceBookEntries
 */
UserSummary.prototype['maintenanceBookEntries'] = undefined;

/**
 * @member {Date} lastMaintenanceBookEntry
 */
UserSummary.prototype['lastMaintenanceBookEntry'] = undefined;






export default UserSummary;

