/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BuildingOut from './BuildingOut';
import GeographicCoordinates from './GeographicCoordinates';
import PlantTypeOut from './PlantTypeOut';

/**
 * The PlantOut model module.
 * @module model/PlantOut
 * @version v2
 */
class PlantOut {
    /**
     * Constructs a new <code>PlantOut</code>.
     * @alias module:model/PlantOut
     */
    constructor() { 
        
        PlantOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PlantOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PlantOut} obj Optional instance to populate.
     * @return {module:model/PlantOut} The populated <code>PlantOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PlantOut();

            if (data.hasOwnProperty('guid')) {
                obj['guid'] = ApiClient.convertToType(data['guid'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('plantType')) {
                obj['plantType'] = PlantTypeOut.constructFromObject(data['plantType']);
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = GeographicCoordinates.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('building')) {
                obj['building'] = BuildingOut.constructFromObject(data['building']);
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} guid
 */
PlantOut.prototype['guid'] = undefined;

/**
 * @member {String} name
 */
PlantOut.prototype['name'] = undefined;

/**
 * @member {module:model/PlantTypeOut} plantType
 */
PlantOut.prototype['plantType'] = undefined;

/**
 * @member {module:model/GeographicCoordinates} location
 */
PlantOut.prototype['location'] = undefined;

/**
 * @member {module:model/BuildingOut} building
 */
PlantOut.prototype['building'] = undefined;

/**
 * @member {String} comment
 */
PlantOut.prototype['comment'] = undefined;






export default PlantOut;

