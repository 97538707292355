/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class AlertSubscriptionType.
* @enum {}
* @readonly
*/
export default class AlertSubscriptionType {
    
        /**
         * value: "APP"
         * @const
         */
        "APP" = "APP";

    
        /**
         * value: "EMAIL"
         * @const
         */
        "EMAIL" = "EMAIL";

    
        /**
         * value: "SMS"
         * @const
         */
        "SMS" = "SMS";

    

    /**
    * Returns a <code>AlertSubscriptionType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/AlertSubscriptionType} The enum <code>AlertSubscriptionType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

