import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Modal } from "react-bootstrap";
import ApiClient from "sigma_heat_backend_api_v2/src/ApiClient";
import V2LeadApi from "sigma_heat_backend_api_v2/src/api/V2LeadApi";
import { AbsoluteCenter, FixedForm, FormAlert } from "../style/StyledContainer";
import { SHFormLabel, SHFormText, SHFormEmail, SHFormPassword, SHFormButton, SHFormSelectWrapper, SHFormSelect } from "../style/SigmaHeatTheme";
import Footer from "../widgets/Footer";
import { useTranslation } from "react-i18next";

export default (props) => {
    const [email, setEmail] = useState(null);
    const [company, setCompany] = useState(null);
    const [companyType, setCompanyType] = useState(null);
    const [name, setName] = useState(null);
    const [pw, setPW] = useState(null);
    const [isConsumer, setIsConsumer] = useState(false);
    const api = new ApiClient();

    const [customerTypes, setCustomerTypes] = useState(null);
    const [alert, setAlert] = useState(null);
    const [variant, setVariant] = useState('warning');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        //Replace this with a real call once the api is updated
        const result = [
            {
                "guid": "4bbdd0ad-a85c-4e8c-823a-1da4c6bc54d4",
                "name": "Wohnungsbauunternehmen / -genossenschaft"
            },
            {
                "guid": "4d319097-97e6-49be-aa4c-a2496e3fe884",
                "name": "Unternehmen / KMU"
            },
            {
                "guid": "e38ce1a1-a5e6-428c-858c-935b8c0bf454",
                "name": "Kommune / Kommunaler Träger"
            },
            {
                "guid": "011e2abb-d791-4d59-9505-c65d1c243f2c",
                "name": "Privatkunde"
            },
            {
                "guid": "76cdf79c-ab27-4423-95c0-029373178d54",
                "name": "Handwerker"
            }
        ];
        setCustomerTypes(result);
        setCompanyType(result[0].guid);
    }, []);

    const submit = () => {
        if (!email) {
            setVariant('warning');
            setAlert(t("enterEmail"));
            return;
        }
        if (!name) {
            setVariant('warning');
            setAlert(t("enterName"));
            return;
        }
        if (!pw) {
            setVariant('warning');
            setAlert(t("enterPassword"));
            return;
        }

        let effectiveCompany;
        if (isConsumer) effectiveCompany = name;
        else effectiveCompany = company;
        const lead = {
            name: effectiveCompany,
            address: {
                "name": effectiveCompany,
                "street": "",
                "zip": "",
                "town": "",
                "province": "",
                "country": ""
            },
            customerType: companyType,
            required: {
                "Password": pw,
                "User": name,
                "Email": email
            },
            optional: {}
        };
        new V2LeadApi(api).v2LeadPost({ leadIn: lead }, (error, data, request) => {
            if (!error) {
                console.log({ SignUp: data });
                setVariant('success');
                setAlert(t("registrationSuccessful"));
                setInterval(props.close, 3000);
            } else {
                console.error({ SignUpError: error });
                setVariant('warning');
                setAlert(t("registrationUnsuccessful"));
            }
        });
        console.log("Submitted Lead", lead)
    };
    const getForm = () => {
        return <>
            <FixedForm>
                {alert && <FormAlert variant={variant}>{alert}</FormAlert>}
                <Form.Group className="mb-3" controlId="formEmail">
                    <SHFormLabel>E-Mail</SHFormLabel>
                    <SHFormEmail placeholder="beispiel@sigmaheat.de" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                </Form.Group>
                {customerTypes &&
                    <Form.Group id="formCompanyType" className="mb-4">
                        <SHFormLabel>{t("customerType")}</SHFormLabel>
                        <SHFormSelectWrapper>
                            <SHFormSelect onChange={(e) => { setCompanyType(e.target.value); setIsConsumer(e.target.value == "011e2abb-d791-4d59-9505-c65d1c243f2c"); }} value={companyType}>
                                {customerTypes.map((cT) => <option key={'key-' + cT.guid} value={cT.guid}>{cT.name}</option>)}
                            </SHFormSelect>
                        </SHFormSelectWrapper>
                    </Form.Group>
                }
                {!isConsumer &&
                    <Form.Group className="mb-3" controlId="formCompany">
                        <SHFormLabel>Firma</SHFormLabel>
                        <SHFormText placeholder="Firma" value={company} onChange={(e) => { setCompany(e.target.value) }} />
                    </Form.Group>
                }
                <Form.Group className="mb-3" controlId="formName">
                    <SHFormLabel>Name</SHFormLabel>
                    <SHFormText placeholder="Max Mustermann" value={name} onChange={(e) => { setName(e.target.value) }} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPassword">
                    <SHFormLabel>{t("password")}</SHFormLabel>
                    <SHFormPassword placeholder="Password" value={pw} onChange={(e) => { setPW(e.target.value) }} />
                </Form.Group>
                <div className="d-flex justify-content-between">
                    <SHFormButton onClick={submit}>{t("register")}</SHFormButton>
                    <SHFormButton onClick={props.close}>{t("cancel")}</SHFormButton>
                </div>
            </FixedForm>
        </>
    }

    return <>{
    (!props.asModal) ?
        <><AbsoluteCenter>{getForm()}</AbsoluteCenter><Footer /></> :
        <><Modal size='l' as={Modal.Dialog} centered show onHide={props.close}>
            <Modal.Header><Modal.Title className="h4">{t("registerUser")}</Modal.Title></Modal.Header>
            <Modal.Body className="p-3">{getForm()}</Modal.Body>
        </Modal></>}</>
};