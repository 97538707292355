import i18next from "i18next"
import {initReactI18next} from "react-i18next"

i18next.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                homePage: "Home Page",
                property: "Property",
                properties: "Properties",
                alarms: "Alarms",
                users: "Users",
                administrationArea: "Administration Area",
                edit: "Edit",
                clientsAccount: "Clients Account",
                settings: "Settings",
                logout: "Logout",
                changeLanguage: "Change Language",
                german: "German",
                english: "English",
                deviceName: "Device Name",
                device: "Device",
                devices: "Devices",
                noDevices: "No devices available",
                occurredError: "An error occurred during loading the devices. Please report this error at us.",
                addDevice: "Add Device",
                unlockDevice: "Unlock Device",
                unlockKey: "Unlock Key",
                deviceAssociationMessage: "This token and key could not be assigned to a device.",
                building: "Building",
                buildings: "Buildings",
                numOfBuildings: "Number of buildings",
                noBuilding: "No building",
                key: "Key",
                confirm: "Confirm",
                assignDevice: "Assign activation data for device",
                editDeviceTooltip: "Edit measurement series of the device",
                cableNumber: "Cable Number",
                measPoint: "Measurement Point",
                measurementPoint: "Measurement Point Name",
                metric: "Metric",
                loading: "Loading",
                noMeasurementsEntry: "There are no measurements data to display",
                noMeasurementValue: "No Measurement Value",
                measurementValue: "Measurement Value",
                minuteValues: "Minute Values",
                oneDay: "1 Day",
                hourValues: "Hour Values",
                yearlyValues: "Yearly Values",
                quarterHourValues: "Quarter Hour Values",
                sevenDays: "7 Days",
                fourteenDays: "Last 14 Days",
                dayValues: "Day Values",
                thirtyDays: "30 Days",
                ninetyDays: "90 Days",
                oneYearDays: "365 Days",
                fiftytwoWees: "52 Weeks",
                fiveYears: "5 Years",
                showPlot: "Show Plot",
                changeMeasurementPointName: "Change measurement point name",
                online: "Online",
                devicename: "Device Name",
                lastupdate: "Last Update",
                sensors: "Sensors",
                sensor: "Sensor",
                note: "Note",
                configurate: "Configurate",
                minimalValue: "Minimal Value",
                average: "Average",
                maximalValue: "Maximal Value",
                minutely: "Minutely",
                quarterhourly: "Quarterhourly",
                hourly: "Hourly",
                daily: "Daily",
                period: "Period",
                aggregation: "Aggregation",
                interval: "Interval",
                reload: "Reload",
                downloadAsCSV: "Download as CSV",
                switch: "Switch",
                noDataAvailable: "No Data available",
                firstPage: "First Page",
                previous: "Previous",
                next: "Next",
                lastPage: "Last Page",
                individualMeasurementPoints: "Individual measurement points",
                heatingCurve: "Heating Curve",
                diagram: "Diagram",
                individualDiagram: "Individual diagram",
                selectMeasurementPoints: "Select Measurement Points",
                preview: "Preview",
                report: "If you are reading this message, please report at us.",
                noProperties: "There isn't any property assigned to this user",
                systemPointName: "System point name",
                nameOfTheMeasurementSeries: "Name of the measurement series",
                unitOfMeasurementSeries: "Unit of measurement series",
                lastUploadLastValue: "Last Upload / Last Value",
                undefined: "Undefined",
                plant: "Plant",
                plants: "Plants",
                type: "Type",
                comment: "Comment",
                comments: "Comments",
                address: "Address",
                noOfPlants: "Number of plants",
                create: "Create",
                plantName: "Plant Name",
                remarks: "Remarks",
                typeOfPlant: "Type of plant",
                confirmAndCreate: "Confirm and create",
                editBuilding: "Edit Building",
                apply: "Apply",
                postalCode: "Postal Code",
                city: "City",
                allEvents: "All events",
                allAlarmsThatHaveBeenSet: "All alarms that have been set",
                noResults: "No Results",
                alarmAcknowledged: "Alarm acknowledged",
                acknowledgment: "Acknowledgment",
                hysteresis: "Hysteresis",
                termination: "Termination",
                duration: "Duration",
                tillNow: "Till Now",
                fiveMinutes: "5 Minutes",
                fifteenMinutes: "15 Minutes",
                sixtyMinutes: "60 Minutes",
                twentyFourHours: "24 Hours",
                active: "Active",
                measurement: "Measurement",
                limit: "Limit",
                time: "Time",
                acknowledged: "Acknowledged",
                deleteAlarm: "Alarm delete",
                deleteAlarmError: "There was an error deleting the alarm. Please contact support.",
                cancel: "Cancel",
                editAlarm: "Edit Alarm",
                createNewAlarm: "Create new alarm",
                noAlarmEntry: "There are no existing alarms set",
                measurementSeries: "Measurement series",
                trigger: "Trigger",
                fallsBelow: "Falls below",
                supasses: "Surpasses",
                takePhotoError: "An error occurred while triggering a Digimeta image. Please contact support.",
                loadPhotoError: "There was an error loading a Digimeta image. Please contact support.",
                loadDigimetaError: "There was an error loading Digimeta information. Please contact support.",
                loadDigimetaPhotosError: "There was an error loading a Digimeta image. Please contact support.",
                digimetaConfiguration: "Digimeta Configuration",
                lastPhoto: "Last Photo",
                takePhoto: "Take a photo",
                makeNewConfiguration: "Make a new configuration",
                loadLastPhoto: "Load last photo",
                actualConfiguration: "Actual Configuration",
                newConfiguration: "New Konfiguration",
                left: "Left",
                right: "Right",
                top: "Top",
                bottom: "Bottom",
                rotation: "Rotation",
                transferNewConfiguration: "Transfer new configuration",
                finishConfiguration: "Finish configuration",
                noDigimeta: "No Digimeta recordings available. Contact support.",
                value: "Value",
                manual: "Manual",
                noUsers: "There are no users. Add a new one.",
                permissions: "Permissions",
                addUser: "Add User",
                user: "User",
                actions: "Actions",
                passwordInitialMsg: "To change the password, you must enter the new password twice below. After changing the password you will be logged out and have to log in again with the new password.",
                matchPasswords: "The passwords do not match!",
                newPassword: "New Password",
                repeat: "Repeat",
                buildingUpdateError: "Building could not be uploaded",
                buildingSuccessfulUpdated: "The buildings have been updated successfully!",
                requiredDataMessage: "Please provide all required information.",
                userEdit: "Edit User",
                contactDetails: "Contact Details",
                changePassword: "Change Password",
                add: "Add",
                deleteUser: "Delete User",
                usersBuildings: "Users Buildings",
                deleteBuilding: "Delete Building",
                propertyName: "Property Name",
                propertyNameMessage: "Please enter a name for property",
                propertyLocationMessage: "Please enter the location of the property either by clicking on the map or by entering the correct coordinates",
                createProperty: "Create Property",
                createBuilding: "Create Building",
                buildingName: "Building Name",
                createAnotherBuilding: "Create another building",
                deviceAssociation: "Device Association",
                plantMessage: "Plant could not be created.",
                createPlant: "Create Plant",
                setupPlant: "Setup Plant",
                addSystempoint: "Add Systempoint",
                userSetupError: "An error has occurred. Please report this message to us.",
                cookiesFirstSentence: "Please agree to the processing of the data so that we can offer you our service.",
                cookiesSecondSentence: "you can find our Privacy Policy.",
                oneWeek: "One Week",
                fourWeek: "Four Weeks",
                noAssignedDigimeta: "Zero Digimeta related to this account",
                classify: "Classify",
                deviceOwner: "Device Owner",
                classifyDigimeta: "Classify Digimeta",
                alreadyConverted: "Already Converted",
                data: "Data",
                uploaded: "Uploaded",
                owner: "Owner",
                missing: "Missing",
                sameConsumption: "Same Consumption",
                rotate: "Rotate",
                old: "Old",
                prediction: "Prediction",
                date: "Date",
                addCustomers: "Add Customers",
                customerInformation: "Customer Information",
                company: "Company",
                customerGroup: "Customer Group",
                discard: "Verwerfen",
                justRead: "Just Read",
                readWriteNoAdmin: "Read and write, no admin",
                delete: "Delete",
                noUser: "No User",
                rights: "Rights",
                addProperty: "Add Property",
                phoneNumber: "Phone Number",
                addRecipient: "Add Recipient",
                noRecipient: "No Recipient",
                errorOnLoad: "Error on Load",
                recipient: "Recipient",
                buildingData: "Building Data",
                heatedSurface: "heated Surface",
                consumedEfficiencyClass: "Energy efficiency class according to consumption",
                consumedEnergyPerYear: "Energy consumption per m² and year",
                calculatedEfficiencyClass: "Energy efficiency class as required",
                calculatedEnergyPerYear: "Energy requirement per m² and year",
                year: "Year",
                buildingCharacteristics: "Building Characteristics",
                buildingMap: "Building Map",
                markersOnly: "Markers only",
                availableSoon: "Available Soon",
                alarmMap: "Alarm Map",
                consumptionMap: "Consumption Map",
                changeDeviceName: "Change Device Name",
                changeDeviceNote: "Change device note",
                all: "All",
                lastDay: "Last Day",
                customerAccounts: "Customer Accounts",
                noCustomersAssigned: "No customers assigned",
                releaseAsCustomer: "Release as customer",
                noLead: "No Lead",
                clientGenerationFromLead: "Client was generated from Lead",
                errorOnConvertion: "Error on convertion",
                addLead: "Add Lead",
                incorrectUsernamePassword: "Incorrect username or password!",
                password: "Password",
                login: "Login",
                register: "Register",
                enterEmail: "Please enter your Email address",
                enterName: "Please enter your name",
                enterPassword: "Please enter a password for your account",
                registrationSuccessful: "Successfully registered. In just a few seconds you will be redirected to the login page.",
                registrationUnsuccessful: "Something went wrong during registration, please contact the web administrator.",
                customerType: "Customer Type",
                registerUser: "Register User",
                newEntry: "New Entry",
                openUpdate: "Open / Update",
                updateEntry: "Update Entry",
                addNewEntry: "Add new entry",
                fillWater: "Fill Water",
                thermalMaintenance: "Thermal Maintenance",
                gasPressure: "Gas Pressure",
                chimneyDraught: "Chimney Draught",
                content: "Cotent",
                exhaustLoss: "Exhaust Loss",
                efficiency: "Efficiency",
                heatingCapacity: "Heating Capacity",
                roomTemperature: "Room Temperature",
                exhaustTemperature: "Exhaust Temperature",
                individualEntry: "Individual Entry",
                yes: "Yes",
                no: "No",
                makeInhibitor: "Make inhibitator",
                designation: "Designation",
                details: "Details",
                deletePlant: "Delete Plant",
                maintenanceBook: "Maintenance Book",
                deletePlantPrompt: "Do you really want to delete this plant?",
                noPlantName: "No Plant Name",
                quickEdit: "Quickedit",
                everythingClean: "All clean. No unacknowledged alarms!",
                searchPBA: "Search Property, Building or Address",
                changePasswordError: "The password could not be changed. Please contact support.",
                streetNo: "Street, Nr.",
                buildingSelectSearch: "Select the facility building",
                yourPosition: "You are here",
                notYetDeposited: "not yet deposited",
                consumptionEEC: "Consumption / Energy Efficiency Class",
                last365Days: "in the last 365 days",
                accordingToEC: "according to Energy Certificate",
                totheBuilding: "To the building",
                editMeterReadings: "Edit meter readings",
                pulse: "Pulse",
                camera: "Camera",
                justNegative: "Just negative",
                prePreValue: "Pre-prevalue",
                preValue: "Previous Value",
                difference: "Difference",
                afterValue: "After Value",
                transferChangedValues: "Transfer changed values",
                privacy: "Privacy",
                imprint: "Imprint",
                oClock: "o'Clock",
                monday: "Monday",
                tuesday: "Tuesday",
                wednesday: "Wednesday",
                thursday: "Thursday",
                friday: "Friday",
                saturday: "Saturday",
                sunday: "Sunday",
                otherEntry: "Other entry",
                title: "Title",
                author: "Author",
                createdOn: "Created On",
                clients: "Clients",
                loginError: "The username or the password you have provided is incorrect. Please verify your input.",
                resetYourPassword: "Reset Your Password",
                resetInstructions: "We will email you instructions to reset your password.",
                resetPassword: "Reset Password",
                returnLogin: "Return to login",
                forgotPassword: "Forgot Password?",
                emailSend: "Email wurde erfolgreich Versendet",
                emailSendInstructions: "In Kürze erhalten Sie von uns eine E-Mail mit den nächsten Anweisungen zum Ändern des Passworts",
                passwordChanged: "Password was changed successfully",
                dataAnalysis: "Data Analysis",
                noNote: "(No Note)",
                editReceiver: "Edit Receiver",
                deleteAlarm: "Delete Alarm",
                noValue: "No Value",
            }
        },
        de: {
            translation: {
                homePage: "Startseite",
                property: "Liegenschaft",
                properties: "Liegenschaften",
                alarms: "Alarme",
                users: "Benutzer",
                administrationArea: "Administration-Bereich",
                edit: "Bearbeiten",
                clientsAccount: "Kundenkonto",
                settings: "Einstellungen",
                logout: "Ausloggen",
                changeLanguage: "Sprache ändern",
                german: "Deutsch",
                english: "Englisch",
                deviceName: "Gerätename",
                device: "Gerät",
                devices: "Geräte",
                noDevices: "Keine Geräte vorhanden",
                occurredError: "Beim Laden der Geräte ist ein Fehler aufgetreten. Bitte Laden Sie die Seite neu. Tritt dieser Fehler häufiger auf, kontaktieren Sie uns bitte.",
                addDevice: "Gerät hinzufügen",
                unlockDevice: "Gerät freischalten",
                unlockKey: "Freischaltungs-Schlüssel",
                deviceAssociationMessage: "Dieses Token und dieser Schlüssel konnten keinem Gerät zugeordnet werden.",
                building: "Gebäude",
                buildings: "Gebäude",
                numOfBuildings: "Anzahl Gebäude",
                noBuilding: "Keine Gebäude",
                key: "Schlüssel",
                confirm: "Bestätigen",
                assignDevice: "Freischaltungsdaten für Gerät vergeben",
                editDeviceTooltip: "Messreihen des Gerätes bearbeiten",
                cableNumber: "Kabelnummer",
                measPoint: "Messpunkt",
                measurementPoint: "Messpunktname",
                metric: "Messgröße",
                loading: "Laden...",
                noMeasurementsEntry: "Es sind keine anzuzeigenden Messdaten vorhanden",
                noMeasurementValue: "Keine Messwerte",
                measurementValue: "Messwerte",
                minuteValues: "Minutenwerte",
                oneDay: "1 Tag",
                hourValues: "Stundenwerte",
                yearlyValues: "Jahreswerte",
                quarterHourValues: "Viertelstundenwerte",
                sevenDays: "7 Tage",
                fourteenDays: "Letzten 14 Tage",
                dayValues: "Tageswerte",
                thirtyDays: "30 Tage",
                ninetyDays: "90 Tage",
                oneYearDays: "365 Tage",
                fiftytwoWees: "52 Wochen",
                fiveYears: "5 Jahre",
                showPlot: "Plot anzeigen",
                changeMeasurementPointName: "Messpunktname ändern",
                online: "Online",
                devicename: "Gerätename",
                lastupdate: "Letztes Update",
                sensors: "Sensoren",
                sensor: "Sensor",
                note: "Notiz",
                configurate: "Konfigurieren",
                minimalValue: "Minimalwert",
                average: "Durchschnitt",
                maximalValue: "Maximalwert",
                minutely: "Minute",
                quarterhourly: "Viertelstunde",
                hourly: "Stunde",
                daily: "Tag",
                period: "Zeitraum",
                aggregation: "Anhäufung",
                interval: "Intervall",
                reload: "Neu laden",
                downloadAsCSV: "Als CSV herunterladen",
                switch: "Tauschen",
                noDataAvailable: "Keine Daten verfügbar",
                firstPage: "Erste Seite",
                previous: "Zurück",
                next: "Nächste",
                lastPage: "Letzte Seite",
                individualMeasurementPoints: "Einzelne Messpunkte",
                heatingCurve: "Heizkurve",
                diagram: "Diagramm",
                individualDiagram: "Individuelles Diagramm",
                selectMeasurementPoints: "Messpunkte auswählen",
                preview: "Vorschau",
                report: "Falls Sie diese Nachricht lesen, melden Sie sich bei uns an.",
                noProperties: "Es gibt keine Liegenschaften, die diesem Benutzer zugeordnet sind.",
                systemPointName: "Name des Systempunkts",
                nameOfTheMeasurementSeries: "Name der Messreihe",
                unitOfMeasurementSeries: "Einheit der Messreihe",
                lastUploadLastValue: "Letzte Aktualisierung / Letzte Wert",
                undefined: "Undefiniert",
                plant: "Anlage",
                plants: "Anlagen",
                type: "Typ",
                comment: "Kommentar",
                comments: "Kommentare",
                address: "Adresse",
                noOfPlants: "Anzahl Anlagen",
                create: "Erstellen",
                plantName: "Name der Anlage",
                remarks: "Anmerkungen",
                typeOfPlant: "Art der Anlage",
                confirmAndCreate: "Bestätigen und erstellen",
                editBuilding: "Edit Building",
                apply: "Übernehmen",
                postalCode: "PLZ",
                city: "Stadt",
                allEvents: "Alle Ereignisse",
                allAlarmsThatHaveBeenSet: "Alle eingestellten Alarme",
                noResults: "Keine Ereignisse",
                alarmAcknowledged: "Alarm quittiert",
                acknowledgment: "Quittierung",
                hysteresis: "Hysterese",
                termination: "Ende",
                duration: "Dauer",
                tillNow: "Bis jetzt",
                fiveMinutes: "5 Minuten",
                fifteenMinutes: "15 Minuten",
                sixtyMinutes: "60 Minuten",
                twentyFourHours: "24 Stunden",
                active: "Aktiv",
                measurement: "Messung",
                limit: "Grenzwert",
                time: "Zeitpunkt",
                acknowledged: "Quittiert",
                deleteAlarm: "Alarm löschen",
                deleteAlarmError: "Beim Löschen des Alarms gab es einen Fehler. Bitte wenden Sie sich an den Support.",
                cancel: "Abbruch",
                editAlarm: "Alarm bearbeiten",
                createNewAlarm: "Neuen Alarm erstellen",
                noAlarmEntry: "Es sind keine eingestellte Alarme vorhanden",
                measurementSeries: "Messreihe",
                trigger: "Auslöser",
                fallsBelow: "Unterschreitung",
                supasses: "Überschreitung",
                takePhotoError: "Beim Auslösen eines Digimeta-Bildes ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
                loadPhotoError: "Beim Laden eines Digimeta-Bildes ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
                loadDigimetaError: "Beim Laden von Digimeta-Informationen ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
                loadDigimetaPhotosError: "Beim Laden eines Digimeta-Bildes ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
                digimetaConfiguration: "Digimeta-Konfiguration",
                lastPhoto: "Last Photo",
                takePhoto: "Aufnahme auslösen",
                makeNewConfiguration: "Neue Konfiguration vornehmen",
                loadLastPhoto: "Load last photo",
                actualConfiguration: "Aktuelle Konfiguration",
                newConfiguration: "Neue Konfiguration",
                left: "Links",
                right: "Rechts",
                top: "Oben",
                bottom: "Unten",
                rotation: "Drehung",
                transferNewConfiguration: "Neue Konfiguration übertragen",
                finishConfiguration: "Konfiguration beenden",
                noDigimeta: "Keine Digimeta-Aufzeichnungen verfügbar. Wenden Sie sich an den Support.",
                value: "Wert",
                manual: "Manuell",
                noUsers: "There are no users. Add a new one.",
                permissions: "Berechtigungen",
                addUser: "Nutzer hinzufügen",
                user: "Nutzer",
                actions: "Aktionen",
                passwordInitialMsg: "Um das Passwort zu ändern, müssen Sie das neue Passwort unten zweimal eingeben. Nach dem Ändern des Passwortes werden Sie ausgeloggt und müssen sich mit dem neuen Passwort erneut anmelden.",
                matchPasswords: "Die Passwörter stimmen nicht überein!",
                newPassword: "Neues Passwort",
                repeat: "Wiederholen",
                buildingUpdateError: "Gebäude konnte nicht hochgeladen werden",
                buildingSuccessfulUpdated: "Die Gebäude würde erfolgreich aktualisiert!",
                requiredDataMessage: "Bitte gebe alle erforderlichen Daten an.",
                userEdit: "User bearbeiten",
                contactDetails: "Kontaktdaten",
                changePassword: "Passwort ändern",
                add: "Hinzufügen",
                deleteUser: "Nutzer löschen",
                usersBuildings: "Gebäude des Users",
                deleteBuilding: "Gebäude löschen",
                propertyName: "Leigenschaftsname",
                propertyNameMessage: "Bitte geben Sie einen Namen für die Liegenschaft ein",
                propertyLocationMessage: "Bitte geben Sie den Standort der Liegenschaft ein, indem Sie entweder auf die Karte klicken oder die richtigen Koordinaten eingeben",
                createProperty: "Leigenschaft Erstellen",
                createBuilding: "Gebäude Erstellen",
                buildingName: "Gebäudesname",
                createAnotherBuilding: "Ein weiteres Gebäude erstellen",
                deviceAssociation: "Geräteassoziation",
                plantMessage: "Anlage konnte nicht erstellt werden.",
                createPlant: "Anlage Erstellen",
                setupPlant: "Anlage Einrichten",
                addSystempoint: "Systempunkt hinzufügen",
                userSetupError: "Ein Fehler ist aufgetreten. Bitte melden Sie diese Meldung bei uns.",
                cookiesFirstSentence: "Bitte stimmen Sie der Verarbeitung der Daten zu, damit wir Ihnen unseren Service anbieten können.",
                cookiesSecondSentence: "finden Sie unsere Datenschutzerklärung.",
                oneWeek: "Eine Woche",
                fourWeek: "Vier Wochen",
                loginError: "Der Nutzername oder das Passwort sind falsch. Bitte überprüfen Sie Ihre Eingabe!",
                noAssignedDigimeta: "Null Digimeta in Bezug auf dieses Konto",
                classify: "Klassifizieren",
                deviceOwner: "Gerätebesitzer",
                classifyDigimeta: "Digimeta klassifizieren",
                alreadyConverted: "Bereits umgewandelt",
                data: "Daten",
                uploaded: "Hochgeladen",
                owner: "Besitzer",
                missing: "Fehlender",
                sameConsumption: "Gleicher Verbrauch",
                rotate: "Drehen",
                old: "Alter",
                prediction: "Vorhersage",
                date: "Datum",
                addCustomers: "Kunden hinzufügen",
                customerInformation: "Kundeninformationen",
                company: "Unternehmen",
                customerGroup: "Kundengruppe",
                discard: "Discard",
                justRead: "Nur Lesen",
                readWriteNoAdmin: "Lesen und Schreiben, kein Admin",
                delete: "Löschen",
                noUser: "Kein Nutzer",
                rights: "Rechte",
                addProperty: "Liegenschaft hinzufügen",
                phoneNumber: "Telefonnummer",
                addRecipient: "Empfänger hinzufügen",
                noRecipient: "Keine Empfänger",
                errorOnLoad: "Fehler beim Laden",
                recipient: "Empfänger",
                buildingData: "Gebäudedaten",
                heatedSurface: "beheizte Fläche",
                consumedEfficiencyClass: "Energieeffizienzklasse nach Verbrauch",
                consumedEnergyPerYear: "Energieverbrauch pro m² und Jahr",
                calculatedEfficiencyClass: "Energieeffizienzklasse nach Bedarf",
                calculatedEnergyPerYear: "Energiebedarf pro m² und Jahr",
                year: "Jahr",
                buildingCharacteristics: "Gebäudekenndaten",
                buildingMap: "Gebäudekarte",
                markersOnly: "Nur Marker",
                availableSoon: "Demnächst verfügbar",
                alarmMap: "Alarmkarte",
                consumptionMap: "Verbrauchskarte",
                changeDeviceName: "Gerätename ändern",
                changeDeviceNote: "Notiz zum Gerät ändern",
                all: "Alle",
                lastDay: "Tagesletzte",
                customerAccounts: "Kundenkonten",
                noCustomersAssigned: "Keine Kunden zugewiesen",
                releaseAsCustomer: "Als Kunde freigeben",
                noLead: "Kein Lead",
                clientGenerationFromLead: "Kunde aus Lead generiert",
                errorOnConvertion: "Fehler bei der Konversion!",
                addLead: "Lead hinzufügen",
                incorrectUsernamePassword: "Falscher Nutzername oder Passwort!",
                password: "Passwort",
                login: "Einloggen",
                register: "Registrieren",
                enterEmail: "Geben Sie bitte Ihre Email-Adresse ein!",
                enterName: "Bitte geben Sie Ihren Namen ein!",
                enterPassword: "Bitte geben Sie ein Passwort für Ihr Konto ein!",
                registrationSuccessful: "Erfolgreich registriert. In nur wenigen Sekunden werden Sie zur Anmeldeseite weitergeleitet.",
                registrationUnsuccessful: "Bei der registrierung ist etwas schief gelaufen wenden sie sich bitte an den Webadministrator.",
                customerType: "Kundenart",
                registerUser: "Benutzer registrieren",
                newEntry: "Neuer Eintrag",
                openUpdate: "Öffnen / Updaten",
                updateEntry: "Eintrag updaten",
                addNewEntry: "Neuen Eintrag hinzufügen",
                fillWater: "Füllwasser",
                thermalMaintenance: "Thermenwartung",
                gasPressure: "Gasdruck",
                chimneyDraught: "Kaminzug",
                content: "Gehalt",
                exhaustLoss: "Abgasverlust",
                efficiency: "Wirkungsgrad",
                heatingCapacity: "Heizleistung",
                roomTemperature: "Raumtemperatur",
                exhaustTemperature: "Abgastemperatur",
                individualEntry: "Individueller Eintrag",
                yes: "Ja",
                no: "Nein",
                makeInhibitor: "Inhibitor Fabrikat",
                designation: "Bezeichnung",
                details: "Einzelheiten",
                deletePlant: "Anlage löschen",
                maintenanceBook: "Wartungsbuch",
                deletePlantPrompt: "Möchten Sie die Anlage wirklich löschen?",
                noPlantName: "Kein Anlagenname",
                quickEdit: "Schnellbearbeitung",
                everythingClean: "Alles sauber. Keine unquittierten Alarme!",
                searchPBA: "Liegenschaft, Gebäude oder Adresse suchen",
                changePasswordError: "Das Passwort konnte nicht geändert werden. Bitte wenden Sie sich an den Support.",
                streetNo: "Straße, Nr",
                buildingSelectSearch: "Wähle das Gebäude der Anlage aus",
                yourPosition: "Du bist da",
                notYetDeposited: "noch nicht hinterlegt",
                consumptionEEC: "Verbrauch / Energieeffizienzklasse",
                last365Days: "in den letzten 365 Tagen",
                accordingToEC: "nach Energieausweis",
                totheBuilding: "Zum Gebäude",
                editMeterReadings: "Zählerstände bearbeiten",
                pulse: "Impuls",
                camera: "Kamera",
                justNegative: "Nur negative",
                prePreValue: "Vorvorwert",
                preValue: "Vorwert",
                difference: "Differenz",
                afterValue: "Nachwert",
                transferChangedValues: "Geänderte Werte übertragen",
                privacy: "Datenschutz",
                imprint: "Impressum",
                oClock: "Uhr",
                monday: "Montag",
                tuesday: "Dienstag",
                wednesday: "Mittwoch",
                thursday: "Donnerstag",
                friday: "Freitag",
                saturday: "Samstag",
                sunday: "Sonntag",
                otherEntry: "Sonstiger Eintrag",
                title: "Titel",
                author: "Autor",
                createdOn: "Erstellt am",
                clients: "Kunden",
                loginError: "Der Benutzername oder das Passwort, das Sie angegeben haben, ist falsch. Bitte überprüfen Sie Ihre Eingabe.",
                resetYourPassword: "Zurücksetzen Ihres Kennworts",
                resetInstructions: "Wir senden Ihnen per E-Mail Anweisungen zum Zurücksetzen Ihres Passworts.",
                resetPassword: "Passwort zurücksetzen",
                returnLogin: "Zurück zum Login",
                forgotPassword: "Passwort vergessen?",
                emailSend: "Email was successfully sent",
                emailSendInstructions: "You will shortly receive an email from us with the next instructions on how to change your password",
                passwordChanged: "Passwort wurde erfolgreich geändert",
                dataAnalysis: "Datenanalyse",
                noNote: "(Keine Anmerkung)",
                editReceiver: "Empfänger bearbeiten",
                deleteAlarm: "Alarm löschen",
                noValue: "Kein Wert",
            }
        }
    },
    lng: localStorage.getItem("lng") || "de",
});

export default i18next;