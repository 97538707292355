/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Address model module.
 * @module model/Address
 * @version v2
 */
class Address {
    /**
     * Constructs a new <code>Address</code>.
     * 
     * @alias module:model/Address
     */
    constructor() { 
        
        Address.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Address</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Address} obj Optional instance to populate.
     * @return {module:model/Address} The populated <code>Address</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Address();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('street')) {
                obj['street'] = ApiClient.convertToType(data['street'], 'String');
            }
            if (data.hasOwnProperty('zip')) {
                obj['zip'] = ApiClient.convertToType(data['zip'], 'String');
            }
            if (data.hasOwnProperty('town')) {
                obj['town'] = ApiClient.convertToType(data['town'], 'String');
            }
            if (data.hasOwnProperty('province')) {
                obj['province'] = ApiClient.convertToType(data['province'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Gets or Sets Name
 * @member {String} name
 */
Address.prototype['name'] = undefined;

/**
 * Gets or Sets Street
 * @member {String} street
 */
Address.prototype['street'] = undefined;

/**
 * Gets or Sets Zip
 * @member {String} zip
 */
Address.prototype['zip'] = undefined;

/**
 * Gets or Sets Town
 * @member {String} town
 */
Address.prototype['town'] = undefined;

/**
 * Gets or Sets Province
 * @member {String} province
 */
Address.prototype['province'] = undefined;

/**
 * Gets or Sets Country
 * @member {String} country
 */
Address.prototype['country'] = undefined;






export default Address;

