/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CustomerIn from '../model/CustomerIn';
import CustomerOut from '../model/CustomerOut';

/**
* V2Customer service.
* @module api/V2CustomerApi
* @version v2
*/
export default class V2CustomerApi {

    /**
    * Constructs a new V2CustomerApi. 
    * @alias module:api/V2CustomerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2CustomerGet operation.
     * @callback module:api/V2CustomerApi~v2CustomerGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/CustomerOut>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit 
     * @param {Number} opts.offset  (default to 0)
     * @param {module:api/V2CustomerApi~v2CustomerGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/CustomerOut>}
     */
    v2CustomerGet(guid, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling v2CustomerGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [CustomerOut];
      return this.apiClient.callApi(
        '/v2/customer', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the v2CustomerPost operation.
     * @callback module:api/V2CustomerApi~v2CustomerPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerIn} opts.customerIn 
     * @param {module:api/V2CustomerApi~v2CustomerPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerOut}
     */
    v2CustomerPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['customerIn'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerOut;
      return this.apiClient.callApi(
        '/v2/customer', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
