import React, {useContext, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {LoginContext} from "../Context";
import V2LoginApi from "sigma_heat_backend_api_v2/src/api/V2LoginApi";
import PasswordAuthIn from "sigma_heat_backend_api_v2/src/model/PasswordAuthIn";
import Signup from "./Signup";
import {AbsoluteCenter, FixedForm} from "../style/StyledContainer";
import {SHFormLabel, SHFormEmail, SHFormPassword, SHFormButton, SHLink} from "../style/SigmaHeatTheme";
import Footer from "../widgets/Footer";
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Forgot, Reset} from "./Forgot";
import {useForceUpdate} from "../util/helpers";

function ErrorModal(props) {
    const { t, i18n } = useTranslation();
    const showError = props.showError;
    const setShowError = props.setShowError;

    return <Modal size="sm" as={Modal.Dialog} centered show={showError} onHide={() => {setShowError(false)}}>
        <Modal.Header>
            <Modal.Title className="h4">Fehler</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
            <span>{t("loginError")}</span>
        </Modal.Body>
    </Modal>
}

function LoginForm(props) {
    const { t, i18n } = useTranslation();
    const email = props.email;
    const setEmail = props.setEmail;
    const pw = props.pw;
    const setPW = props.setPW;
    const setSignup = props.setSignup;
    const forceUpdate = props.forceUpdate;
    const submit = props.submit;
    const signup = props.signup;
    const closeSignup = props.closeSignup;

    
    if (signup) return <Signup asModal={false} close={closeSignup}></Signup>;

    let regex = /\/forgot\/reset\?token=/;
    if (regex.test(window.location.pathname + window.location.search)) return <Reset {...{forceUpdate}} />
    if (window.location.pathname === "/forgot") return <Forgot {...{forceUpdate}} />

    return <FixedForm>
        <Form.Group className="mb-3" controlId="formEmail">
            <SHFormLabel>E-Mail</SHFormLabel>
            <SHFormEmail placeholder="beispiel@sigmaheat.de" value={email} onChange={(e) => {
                setEmail(e.target.value)
            }}/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPassword">
            <SHFormLabel>{t("password")}</SHFormLabel>
            <SHFormPassword placeholder="Passwort" value={pw} onChange={(e) => {
                setPW(e.target.value)
            }}/>
        </Form.Group>
        <SHLink className='mb-3' href="#" onClick={(e) => {e.preventDefault(); window.history.pushState({}, '', '/forgot'); forceUpdate()}}>{t("forgotPassword")}</SHLink>
        <div className="d-flex justify-content-between">
            <SHFormButton type="submit" onClick={submit}>{t("login")}</SHFormButton>
            <SHFormButton onClick={() => {
                setSignup(true)
            }}>{t("register")}</SHFormButton>
        </div>
    </FixedForm>
}

export default (props) => {
    const [email, setEmail] = useState("");
    const [pw, setPW] = useState("");
    const [login, setLogin, api] = useContext(LoginContext);
    const [signup, setSignup] = useState(false);
    const { t, i18n } = useTranslation();
    const [showError, setShowError] = useState(false);
    const forceUpdate = useForceUpdate();

    const submit = (e) => {
        e.preventDefault();
        const passwordAuthIn = new PasswordAuthIn();
        passwordAuthIn.email = email;
        passwordAuthIn.password = pw;
        new V2LoginApi(api).v2LoginPost({passwordAuthIn}, (error, data, response) => {
            if (error == null) {
                data.currentCustomer = data.customers[0];
                setLogin(data);
            } else {
                setShowError(true);
            }
        });
    };

    const closeSignup = () => {
        setSignup(false);
    };


    if (props.asModal) return <>
        <Modal size='l' as={Modal.Dialog} centered show onHide={props.close}>
            <Modal.Header>
                <Modal.Title className="h4">{"Login"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <LoginForm {...{email, setEmail, pw, setPW, setShowError, signup, setSignup, closeSignup, forceUpdate, submit}} />
            </Modal.Body>
        </Modal>
        <ErrorModal {...{showError, setShowError}} />
    </>;

    else return <>
        <AbsoluteCenter>
            <LoginForm {...{email, setEmail, pw, setPW, setShowError, signup, setSignup, closeSignup, forceUpdate, submit}} />
        </AbsoluteCenter>
        <ErrorModal {...{showError, setShowError}} />
        <Footer/>
    </>;
};