import React, { useContext, useEffect, useState } from "react";
import { Button, Pagination, Modal, Row, Col, Table } from 'react-bootstrap';
import { faSortUp, faSortDown, faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import V2DigimetaDigimetaGUIDImageApi from "sigma_heat_backend_api_v2/src/api/V2DigimetaDigimetaGUIDImageApi";
import V2DigimetaPendingApi from "sigma_heat_backend_api_v2/src/api/V2DigimetaPendingApi";
import V2DigimetaPendingCountApi from "sigma_heat_backend_api_v2/src/api/V2DigimetaPendingCountApi";
import { LoginContext } from "../Context";
import styled from "styled-components";
import { DigimetaClassifyForm } from "../forms/DigimetaClassifyForm";
import { dateToString } from "../util/helpers";
import { useTranslation } from "react-i18next";

export const SortFontAwesomeIcon = styled(FontAwesomeIcon)`
    width: auto !important;
    max-width: unset
`;
export const CarouselImage = styled.div`
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
export const Statistics = styled.div`
    display: grid;
    grid-row-gap: 10px;
    margin-top: 10px;
`;
export const StatisticsRow = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    border-radius: 0.25rem;
    padding: 5px;
    &:nth-of-type(1) {
        font-weight: bold;
    }
    &:not(&:nth-of-type(1)) {
        background-color: #f5f5f5;
    }
    & span {
        align-self: center;
    }
`;
export const SubmitValue = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: auto 38px;
    grid-column-gap: 5px;
`;

export const DigimetaList = (props) => {
    const [login, setLogin, api] = useContext(LoginContext);
    const userGuid = login.user.guid;
    const [currentDigimetasPending, setCurrentDigimetasPending] = useState([]);
    const [digimetasPending, setDigimetasPending] = useState([]);
    const [digimetaImages, setDigimetaImages] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [withIcons, setWithIcons] = useState(true);
    const [sort, setSort] = useState({});
    const [digimetaGuidToClassify, setDigimetaGuidToClassify] = useState(undefined);
    const [count, setCount] = useState(0);
    const [loadDigimetaData, setLoadDigimetaData] = useState(false);
    const [elementsPerPage, setElementsPerPage] = useState(100);
    const [rotation, setRotation] = useState(0);
    const { t, i18n } = useTranslation();
    

    const propsData = {
        sort,
        setSort,
        data: digimetasPending,
        withIcons,
        activePage,
        setActivePage,
        totalPages,
        loadDigimetaData,
        setLoadDigimetaData,
        digimetaGuidToClassify,
        setDigimetaGuidToClassify,
        rotation,
        setRotation
    }

    const checkAndLoadDigimetaData = (offset, setToCurrent = false) => {
        if (!digimetasPending.slice(offset, offset + elementsPerPage).every((value) => value != null)) {
            new V2DigimetaPendingApi(api).v2DigimetaPendingGet({offset}, (error, data, response) => {
                let i = 0;
                data = data.map((value) => {
                    return { guid: value, id: (offset + ++i) };
                });
                Array.prototype.splice.apply(digimetasPending, [offset, data.length].concat(data));
                if (setToCurrent) setCurrentDigimetasPending(data);
            })
        } else if (setToCurrent) {
            setCurrentDigimetasPending(digimetasPending.slice(offset, offset + elementsPerPage));
        }
        if (setToCurrent && (offset + elementsPerPage < count)) checkAndLoadDigimetaData(offset + elementsPerPage);
    }

    const reload = () => {
        new V2DigimetaPendingCountApi(api).v2DigimetaPendingCountGet({}, (error, data, response) => {
            console.log(data);
            setLoadDigimetaData(true);
            setCount(data);
            setTotalPages(Math.ceil(data / elementsPerPage));
            setDigimetasPending([...Array(data).keys()].map((value) => null));
        });

        if (loadDigimetaData) {
            setLoadDigimetaData(false);
            checkAndLoadDigimetaData((activePage - 1) * elementsPerPage, true);
        }

        new V2DigimetaPendingApi(api).v2DigimetaPendingGet({ offset: 0, limit: 10 }, (error, data, response) => {
            if (!error && data) {
                const mapToImagePromise = (guidTimestamp) => new Promise(function (resolve, reject) {
                    new V2DigimetaDigimetaGUIDImageApi(api).v2DigimetaDigimetaGUIDImageGet(guidTimestamp.g, (error, data, response) => {
                        if (error) reject(error);
                        const url = URL.createObjectURL(response.body);
                        resolve({ guid: guidTimestamp.g, timestamp: guidTimestamp.t, url });
                    });
                });

                const images = data.map(mapToImagePromise);
                Promise.all(images).then(
                    function (value) {
                        setDigimetaImages(value);
                    },
                    function (error) { }
                );
            }
        })
    };
    useEffect(() => {
        reload();
    }, []);

    if (digimetasPending == null) return <p>{t("noDigimeta")}</p>
    if (digimetasPending.length == 0) return <p>{t("noAssignedDigimeta")}</p>
    if (digimetasPending.length > 0 && props.access === 'admin') {
        return <DigimetaAdministration {...propsData} />;
    }
};

export const DigimetaAdministration = (props) => {
    const [login, setLogin, api] = useContext(LoginContext);
    const { t, i18n } = useTranslation();

    const changeSort = (property) => {
        let sort = props.sort;
        if (sort[property] === undefined) {
            sort[property] = false;
        } else {
            if (!sort[property]) {
                sort[property] = true;
            } else {
                delete sort[property];
            }
        }
        props.setSort(sort);
    }

    const indexOfPropertyToCircle = (property) => {
        let index = Object.keys(props.sort).indexOf(property);

        if (index === -1 || Object.values(props.sort).length < 2) return '';
        return <div style={{
            borderRadius: "100%",
            border: "1px solid black",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20px"
        }}><div>{index + 1}</div></div>;
    }

    const sortColor = (el, asc) => {
        return props.sort[el] === asc ? 'var(--bs-primary)' : '';
    }

    const getSortedCurrentDigimetasPending = () => {
        let array = [...props.data];
        for (const [key, value] of Object.entries(props.sort)) {
            if (typeof value === 'boolean') {
                array = array.sort((a, b) => {
                    let s = key.split('guid.');
                    let c = s.length === 1 ? a[key] : a.guid[s[1]];
                    let d = s.length === 1 ? b[key] : b.guid[s[1]];
                    return (c > d ? 1 : (c < d ? -1 : 0)) * (value ? 1 : -1);
                });
            }
        }
        return array;
    }

    const onPrevItem = (first = false) => {
        const newPage = props.activePage === 1 ? props.activePage : (first ? 1 : props.activePage - 1);
        props.setLoadDigimetaData(true);
        props.setActivePage(newPage);
    };

    const onNextItem = (last = true) => {
        const newPage = props.activePage === props.totalPages ? props.activePage : (last ? props.totalPages : props.activePage + 1);
        props.setLoadDigimetaData(true);
        props.setActivePage(newPage);
    };

    const paginationItems = () => {
        let items = [];
        let start = (props.activePage - 5) > 0 ? props.activePage - 5 : 1;
        let diff = -(props.activePage - 5 - start + 1);
        let end = (props.activePage + 6 + diff) < props.totalPages ? props.activePage + 6 + diff : props.totalPages;


        for (let number = start; number <= end; number++) {
            const handlePaginationChange = () => {
                props.setLoadDigimetaData(true);
                props.setActivePage(number);
            };
            const isItemActive = props.activePage === number;

            items.push(
                <Pagination.Item active={isItemActive} key={number} onClick={handlePaginationChange}>
                    {(number === start && start > 1 && '...') || (number === end && end < props.totalPages - 1 && '...') || number}
                </Pagination.Item>
            );
        };
        return items;
    }

    const closePopUp = () => {
        props.setDigimetaGuidToClassify(undefined);
    }

    const getNextDigimetaToClassify = (sortedDigimetas) => {
        if (!props.digimetaGuidToClassify || !sortedDigimetas || sortedDigimetas.length <= 1) return undefined;
        let index = sortedDigimetas.findIndex((value) => value.guid.g === props.digimetaGuidToClassify);
        if (index === -1 || ((index + 1) >= sortedDigimetas.length)) return undefined;
        return sortedDigimetas[index + 1].guid.g;
    }

    const submittedDigimetaValue = (args) => {
        props.setRotation(args.rotation);
        props.setDigimetaGuidToClassify(getNextDigimetaToClassify);
    }

    const classify = (guid) => {
        props.setDigimetaGuidToClassify(guid);
    }

    const TableRow = (data) => {
        return (
            <tr className="align-middle">
                <td>
                    {data.id}
                </td>
                <td className="text-left">
                    <Button variant="primary" size="sm" onClick={() => { classify(data.guid.g) }}>{t("classify")}</Button>
                </td>
                <td>
                    {data.guid.g}
                </td>
                <td>
                    {dateToString(data.guid.t)}
                </td>
                <td>

                </td>
            </tr>
        );
    };

    const [sortedDigimetas, setSortedDigimetas] = useState([]);

    useEffect(() => {
        setSortedDigimetas(props.data ? getSortedCurrentDigimetasPending() : undefined);
    }, []);

    return (
        <>
            <h5>Digimeta</h5>
            <Table responsive striped hover className="table-centered rounded mb-0">
                <thead className="thead-light">
                    <tr >
                        <th className="border-0 align-middle min-td">#</th>
                        <th className="border-0 min-td"></th>
                        <th className="border-0 align-middle min-td" >guid</th>
                        <th className="border-0  justify-content-center" style={{ cursor: 'pointer' }} onClick={() => { changeSort('guid.t') }}>
                            <Row className="flex-nowrap align-items-center">
                                <Col sm={9}>{t("date")}</Col>
                                <Col sm={1}>{indexOfPropertyToCircle('guid.t')}</Col>
                                <Col sm={2}>
                                    <Row>
                                        <SortFontAwesomeIcon icon={faSortUp} style={{ color: sortColor('guid.t', true) }} />
                                    </Row>
                                    <Row>
                                        <SortFontAwesomeIcon icon={faSortDown} style={{ color: sortColor('guid.t', false) }} />
                                    </Row>
                                </Col>
                            </Row>
                        </th>
                        <th className="border-0  justify-content-center" style={{ cursor: 'pointer' }} onClick={() => { changeSort('owner') }}>
                            <Row className="flex-nowrap align-items-center">
                                <Col sm={9}>{t("deviceOwner")}</Col>
                                <Col sm={1}>{indexOfPropertyToCircle('owner')}</Col>
                                <Col sm={2}>
                                    <Row>
                                        <SortFontAwesomeIcon icon={faSortUp} style={{ color: sortColor('owner', true) }} />
                                    </Row>
                                    <Row width='0'>
                                        <SortFontAwesomeIcon icon={faSortDown} style={{ color: sortColor('owner', false) }} />
                                    </Row>
                                </Col>
                            </Row>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedDigimetas && sortedDigimetas.map(digimeta => digimeta != null && <TableRow key={`digimeta-${digimeta.guid.g}`}{...digimeta} />)}
                </tbody>
            </Table>
            <Pagination size="sm" className="mt-3 flex-wrap">
                {props.withIcons && <Pagination.Prev onClick={() => onPrevItem(true)}>
                    {props.withIcons ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : "First"}
                </Pagination.Prev>}
                <Pagination.Prev onClick={() => onPrevItem(false)}>
                    {props.withIcons ? <FontAwesomeIcon icon={faAngleLeft} /> : "Previous"}
                </Pagination.Prev>
                {paginationItems}
                <Pagination.Next onClick={() => onNextItem(false)}>
                    {props.withIcons ? <FontAwesomeIcon icon={faAngleRight} /> : "Next"}
                </Pagination.Next>
                {props.withIcons && <Pagination.Next onClick={() => onNextItem(true)}>
                    {props.withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Last"}
                </Pagination.Next>}
            </Pagination>
            <Modal size='xl' as={Modal.Dialog} centered show={!!props.digimetaGuidToClassify} onHide={() => { props.closePopUp() }}>
                <Modal.Header>
                    <Modal.Title className="h4">{t("classifyDigimeta")}</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={() => { props.closePopUp() }} />
                </Modal.Header>
                <DigimetaClassifyForm key={props.digimetaGuidToClassify} guid={props.digimetaGuidToClassify} onSubmit={submittedDigimetaValue} rotation={props.rotation || 0} />
            </Modal>
        </>
    );

};