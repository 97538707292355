/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DigimetaGuidWithTimestamp from '../model/DigimetaGuidWithTimestamp';

/**
* V2DeviceDeviceGuidUserDigimeta service.
* @module api/V2DeviceDeviceGuidUserDigimetaApi
* @version v2
*/
export default class V2DeviceDeviceGuidUserDigimetaApi {

    /**
    * Constructs a new V2DeviceDeviceGuidUserDigimetaApi. 
    * @alias module:api/V2DeviceDeviceGuidUserDigimetaApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2DeviceDeviceGuidUserDigimetaGet operation.
     * @callback module:api/V2DeviceDeviceGuidUserDigimetaApi~v2DeviceDeviceGuidUserDigimetaGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/DigimetaGuidWithTimestamp>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} deviceGuid 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.offset 
     * @param {Number} opts.limit 
     * @param {Date} opts.from 
     * @param {Date} opts.to 
     * @param {module:api/V2DeviceDeviceGuidUserDigimetaApi~v2DeviceDeviceGuidUserDigimetaGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/DigimetaGuidWithTimestamp>}
     */
    v2DeviceDeviceGuidUserDigimetaGet(deviceGuid, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'deviceGuid' is set
      if (deviceGuid === undefined || deviceGuid === null) {
        throw new Error("Missing the required parameter 'deviceGuid' when calling v2DeviceDeviceGuidUserDigimetaGet");
      }

      let pathParams = {
        'deviceGuid': deviceGuid
      };
      let queryParams = {
        'offset': opts['offset'],
        'limit': opts['limit'],
        'from': opts['from'],
        'to': opts['to']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [DigimetaGuidWithTimestamp];
      return this.apiClient.callApi(
        '/v2/device/{deviceGuid}/userDigimeta', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
