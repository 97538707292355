/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Address from './Address';
import GeographicCoordinates from './GeographicCoordinates';

/**
 * The BuildingIn model module.
 * @module model/BuildingIn
 * @version v2
 */
class BuildingIn {
    /**
     * Constructs a new <code>BuildingIn</code>.
     * @alias module:model/BuildingIn
     */
    constructor() { 
        
        BuildingIn.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BuildingIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BuildingIn} obj Optional instance to populate.
     * @return {module:model/BuildingIn} The populated <code>BuildingIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BuildingIn();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = Address.constructFromObject(data['address']);
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = GeographicCoordinates.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('property')) {
                obj['property'] = ApiClient.convertToType(data['property'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
BuildingIn.prototype['name'] = undefined;

/**
 * @member {module:model/Address} address
 */
BuildingIn.prototype['address'] = undefined;

/**
 * @member {String} comment
 */
BuildingIn.prototype['comment'] = undefined;

/**
 * @member {module:model/GeographicCoordinates} location
 */
BuildingIn.prototype['location'] = undefined;

/**
 * @member {String} property
 */
BuildingIn.prototype['property'] = undefined;






export default BuildingIn;

