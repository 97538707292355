/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DigimetaValue from './DigimetaValue';
import ImageOut from './ImageOut';
import MeasurementOut from './MeasurementOut';

/**
 * The DigimetaOut model module.
 * @module model/DigimetaOut
 * @version v2
 */
class DigimetaOut {
    /**
     * Constructs a new <code>DigimetaOut</code>.
     * @alias module:model/DigimetaOut
     */
    constructor() { 
        
        DigimetaOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DigimetaOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DigimetaOut} obj Optional instance to populate.
     * @return {module:model/DigimetaOut} The populated <code>DigimetaOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DigimetaOut();

            if (data.hasOwnProperty('guid')) {
                obj['guid'] = ApiClient.convertToType(data['guid'], 'String');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = ImageOut.constructFromObject(data['image']);
            }
            if (data.hasOwnProperty('measurement')) {
                obj['measurement'] = MeasurementOut.constructFromObject(data['measurement']);
            }
            if (data.hasOwnProperty('recordedAt')) {
                obj['recordedAt'] = ApiClient.convertToType(data['recordedAt'], 'Date');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = DigimetaValue.constructFromObject(data['value']);
            }
            if (data.hasOwnProperty('automatic')) {
                obj['automatic'] = DigimetaValue.constructFromObject(data['automatic']);
            }
            if (data.hasOwnProperty('manual')) {
                obj['manual'] = DigimetaValue.constructFromObject(data['manual']);
            }
            if (data.hasOwnProperty('meta')) {
                obj['meta'] = ApiClient.convertToType(data['meta'], {'String': Object});
            }
        }
        return obj;
    }


}

/**
 * @member {String} guid
 */
DigimetaOut.prototype['guid'] = undefined;

/**
 * @member {module:model/ImageOut} image
 */
DigimetaOut.prototype['image'] = undefined;

/**
 * @member {module:model/MeasurementOut} measurement
 */
DigimetaOut.prototype['measurement'] = undefined;

/**
 * @member {Date} recordedAt
 */
DigimetaOut.prototype['recordedAt'] = undefined;

/**
 * @member {module:model/DigimetaValue} value
 */
DigimetaOut.prototype['value'] = undefined;

/**
 * @member {module:model/DigimetaValue} automatic
 */
DigimetaOut.prototype['automatic'] = undefined;

/**
 * @member {module:model/DigimetaValue} manual
 */
DigimetaOut.prototype['manual'] = undefined;

/**
 * @member {Object.<String, Object>} meta
 */
DigimetaOut.prototype['meta'] = undefined;






export default DigimetaOut;

