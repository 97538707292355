import styled from "styled-components";
import { Dropdown, Navbar as BootstrapNavbar } from 'react-bootstrap';
import userMenu from '../assets/Einstellungen_Account_Icon.svg'
import Hamburger from '../assets/Hamburger_Icon.svg'

//Leaflet Map zindex 1000
export const StyledNavbar = styled(BootstrapNavbar)`
    background-color: #edebeb;
    z-index: 1001;
    @media (max-width: 1024px) {
        position: sticky;
        top: 0;
    }
`;

export const BrandToggle = styled.div`
    display: none;
    align-self: center;
    justify-self: center;
    width: 24px;
    height: 24px;
    background-color: #f27d26;
    cursor: pointer;
    mask: url(${Hamburger}) no-repeat center;
    @media (max-width: 1024px) {
        display: block;
    }
`;

export const BrandImage = styled.img.attrs({
    className: 'd-inline-block align-text-top'
})`
    align-self: center;
    height: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

export const BrandSpan = styled.span`
    align-self: center
`;

export const ResponsiveDropDown = styled(Dropdown.Menu).attrs({
    align: 'end'
})`
    width: max-content!important;
    max-width: 256px;
`;

export const Toggle = styled(Dropdown.Toggle)`
    position: relative;
    display: grid;
    grid-template-columns: 36px auto 36px;
    grid-column-gap: 5px;
    background-color: rgba(0, 0, 0, 2.5%);
    border: none;
    border-radius: 32px;
    outline: none;
    padding: 4px;
    transition: background-color 300ms ease;
    &:active:focus,
    &:focus {
        box-shadow: none;
    }
    &::after {
        content: none;
    }
`;

export const ProfileImage = styled.img`
    align-self: center;
    justify-self: center;
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
    :nth-of-type(2) {
        width: 24px;
        height: 24px;
    }
    ${Toggle}:hover &:nth-of-type(2) {
        fill: #ffffff!important;
    }
`;

export const ToggleImage = styled.div`
    align-self: center;
    justify-self: center;
    width: 24px;
    height: 24px;
    background-color: #f27d26;
    mask: url(${userMenu}) no-repeat center;
    ${Toggle}:hover & {
        background-color: #e36f19;
    }
`;

export const UserInformation = styled.div`
    align-self: center;
`;

const InformationCSS = styled.p`
    width: max-content;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    overflow: hidden;
`;

export const InformationFullName = styled(InformationCSS)`
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
`;

export const InformationEmail = styled(InformationCSS)`
    font-size: 12px;
    line-height: 12px;
    color: #424242;
    ${Toggle}:hover & {
        color: #ffffff!important;
    }
`;