/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DigimetaOut from '../model/DigimetaOut';

/**
* V2DigimetaDigimetaGUID service.
* @module api/V2DigimetaDigimetaGUIDApi
* @version v2
*/
export default class V2DigimetaDigimetaGUIDApi {

    /**
    * Constructs a new V2DigimetaDigimetaGUIDApi. 
    * @alias module:api/V2DigimetaDigimetaGUIDApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2DigimetaDigimetaGUIDGet operation.
     * @callback module:api/V2DigimetaDigimetaGUIDApi~v2DigimetaDigimetaGUIDGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DigimetaOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} digimetaGUID 
     * @param {module:api/V2DigimetaDigimetaGUIDApi~v2DigimetaDigimetaGUIDGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DigimetaOut}
     */
    v2DigimetaDigimetaGUIDGet(digimetaGUID, callback) {
      let postBody = null;
      // verify the required parameter 'digimetaGUID' is set
      if (digimetaGUID === undefined || digimetaGUID === null) {
        throw new Error("Missing the required parameter 'digimetaGUID' when calling v2DigimetaDigimetaGUIDGet");
      }

      let pathParams = {
        'digimetaGUID': digimetaGUID
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DigimetaOut;
      return this.apiClient.callApi(
        '/v2/digimeta/{digimetaGUID}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
