/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AlertPatch model module.
 * @module model/AlertPatch
 * @version v2
 */
class AlertPatch {
    /**
     * Constructs a new <code>AlertPatch</code>.
     * @alias module:model/AlertPatch
     */
    constructor() { 
        
        AlertPatch.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AlertPatch</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AlertPatch} obj Optional instance to populate.
     * @return {module:model/AlertPatch} The populated <code>AlertPatch</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AlertPatch();

            if (data.hasOwnProperty('threshold')) {
                obj['threshold'] = ApiClient.convertToType(data['threshold'], 'Number');
            }
            if (data.hasOwnProperty('isUndershoot')) {
                obj['isUndershoot'] = ApiClient.convertToType(data['isUndershoot'], 'Boolean');
            }
            if (data.hasOwnProperty('hysteresis')) {
                obj['hysteresis'] = ApiClient.convertToType(data['hysteresis'], 'Number');
            }
            if (data.hasOwnProperty('measurementGuid')) {
                obj['measurementGuid'] = ApiClient.convertToType(data['measurementGuid'], 'String');
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} threshold
 */
AlertPatch.prototype['threshold'] = undefined;

/**
 * @member {Boolean} isUndershoot
 */
AlertPatch.prototype['isUndershoot'] = undefined;

/**
 * @member {Number} hysteresis
 */
AlertPatch.prototype['hysteresis'] = undefined;

/**
 * @member {String} measurementGuid
 */
AlertPatch.prototype['measurementGuid'] = undefined;

/**
 * @member {Boolean} active
 */
AlertPatch.prototype['active'] = undefined;






export default AlertPatch;

