/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LogType from './LogType';
import OwnedObjectType from './OwnedObjectType';
import UserOut from './UserOut';

/**
 * The ObjectLogEntryOut model module.
 * @module model/ObjectLogEntryOut
 * @version v2
 */
class ObjectLogEntryOut {
    /**
     * Constructs a new <code>ObjectLogEntryOut</code>.
     * @alias module:model/ObjectLogEntryOut
     */
    constructor() { 
        
        ObjectLogEntryOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ObjectLogEntryOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ObjectLogEntryOut} obj Optional instance to populate.
     * @return {module:model/ObjectLogEntryOut} The populated <code>ObjectLogEntryOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ObjectLogEntryOut();

            if (data.hasOwnProperty('logGuid')) {
                obj['logGuid'] = ApiClient.convertToType(data['logGuid'], 'String');
            }
            if (data.hasOwnProperty('logType')) {
                obj['logType'] = LogType.constructFromObject(data['logType']);
            }
            if (data.hasOwnProperty('objectType')) {
                obj['objectType'] = OwnedObjectType.constructFromObject(data['objectType']);
            }
            if (data.hasOwnProperty('objectGuid')) {
                obj['objectGuid'] = ApiClient.convertToType(data['objectGuid'], 'String');
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], Object);
            }
            if (data.hasOwnProperty('occuredAt')) {
                obj['occuredAt'] = ApiClient.convertToType(data['occuredAt'], 'Date');
            }
            if (data.hasOwnProperty('uploader')) {
                obj['uploader'] = UserOut.constructFromObject(data['uploader']);
            }
        }
        return obj;
    }


}

/**
 * @member {String} logGuid
 */
ObjectLogEntryOut.prototype['logGuid'] = undefined;

/**
 * @member {module:model/LogType} logType
 */
ObjectLogEntryOut.prototype['logType'] = undefined;

/**
 * @member {module:model/OwnedObjectType} objectType
 */
ObjectLogEntryOut.prototype['objectType'] = undefined;

/**
 * @member {String} objectGuid
 */
ObjectLogEntryOut.prototype['objectGuid'] = undefined;

/**
 * @member {Object} body
 */
ObjectLogEntryOut.prototype['body'] = undefined;

/**
 * @member {Date} occuredAt
 */
ObjectLogEntryOut.prototype['occuredAt'] = undefined;

/**
 * @member {module:model/UserOut} uploader
 */
ObjectLogEntryOut.prototype['uploader'] = undefined;






export default ObjectLogEntryOut;

