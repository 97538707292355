/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AlertOut from './AlertOut';
import MeasurementOut from './MeasurementOut';
import UserOut from './UserOut';

/**
 * The EventOut model module.
 * @module model/EventOut
 * @version v2
 */
class EventOut {
    /**
     * Constructs a new <code>EventOut</code>.
     * @alias module:model/EventOut
     */
    constructor() { 
        
        EventOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>EventOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/EventOut} obj Optional instance to populate.
     * @return {module:model/EventOut} The populated <code>EventOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new EventOut();

            if (data.hasOwnProperty('guid')) {
                obj['guid'] = ApiClient.convertToType(data['guid'], 'String');
            }
            if (data.hasOwnProperty('measurement')) {
                obj['measurement'] = MeasurementOut.constructFromObject(data['measurement']);
            }
            if (data.hasOwnProperty('alert')) {
                obj['alert'] = AlertOut.constructFromObject(data['alert']);
            }
            if (data.hasOwnProperty('threshold')) {
                obj['threshold'] = ApiClient.convertToType(data['threshold'], 'Number');
            }
            if (data.hasOwnProperty('isUndershoot')) {
                obj['isUndershoot'] = ApiClient.convertToType(data['isUndershoot'], 'Boolean');
            }
            if (data.hasOwnProperty('hysteresis')) {
                obj['hysteresis'] = ApiClient.convertToType(data['hysteresis'], 'Number');
            }
            if (data.hasOwnProperty('triggeredAt')) {
                obj['triggeredAt'] = ApiClient.convertToType(data['triggeredAt'], 'Date');
            }
            if (data.hasOwnProperty('subsidedAt')) {
                obj['subsidedAt'] = ApiClient.convertToType(data['subsidedAt'], 'Date');
            }
            if (data.hasOwnProperty('acknowledgedAt')) {
                obj['acknowledgedAt'] = ApiClient.convertToType(data['acknowledgedAt'], 'Date');
            }
            if (data.hasOwnProperty('acknowledger')) {
                obj['acknowledger'] = UserOut.constructFromObject(data['acknowledger']);
            }
        }
        return obj;
    }


}

/**
 * @member {String} guid
 */
EventOut.prototype['guid'] = undefined;

/**
 * @member {module:model/MeasurementOut} measurement
 */
EventOut.prototype['measurement'] = undefined;

/**
 * @member {module:model/AlertOut} alert
 */
EventOut.prototype['alert'] = undefined;

/**
 * @member {Number} threshold
 */
EventOut.prototype['threshold'] = undefined;

/**
 * @member {Boolean} isUndershoot
 */
EventOut.prototype['isUndershoot'] = undefined;

/**
 * @member {Number} hysteresis
 */
EventOut.prototype['hysteresis'] = undefined;

/**
 * @member {Date} triggeredAt
 */
EventOut.prototype['triggeredAt'] = undefined;

/**
 * @member {Date} subsidedAt
 */
EventOut.prototype['subsidedAt'] = undefined;

/**
 * @member {Date} acknowledgedAt
 */
EventOut.prototype['acknowledgedAt'] = undefined;

/**
 * @member {module:model/UserOut} acknowledger
 */
EventOut.prototype['acknowledger'] = undefined;






export default EventOut;

