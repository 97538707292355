/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DigimetaOut from '../model/DigimetaOut';

/**
* V2DeviceDeviceGuidDigimetaDigimetaGuidValue service.
* @module api/V2DeviceDeviceGuidDigimetaDigimetaGuidValueApi
* @version v2
*/
export default class V2DeviceDeviceGuidDigimetaDigimetaGuidValueApi {

    /**
    * Constructs a new V2DeviceDeviceGuidDigimetaDigimetaGuidValueApi. 
    * @alias module:api/V2DeviceDeviceGuidDigimetaDigimetaGuidValueApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2DeviceDeviceGuidDigimetaDigimetaGuidValuePost operation.
     * @callback module:api/V2DeviceDeviceGuidDigimetaDigimetaGuidValueApi~v2DeviceDeviceGuidDigimetaDigimetaGuidValuePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DigimetaOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Upload a measurement for a given Digimeta Image. This method may be called both with an AppKey and a Login token in the Authorization header.  A user requires the EDIT permission for the measurement
     * @param {String} deviceGuid 
     * @param {String} digimetaGuid 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.auto  (default to false)
     * @param {Number} opts.resetAs 
     * @param {Number} opts.body 
     * @param {module:api/V2DeviceDeviceGuidDigimetaDigimetaGuidValueApi~v2DeviceDeviceGuidDigimetaDigimetaGuidValuePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DigimetaOut}
     */
    v2DeviceDeviceGuidDigimetaDigimetaGuidValuePost(deviceGuid, digimetaGuid, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'deviceGuid' is set
      if (deviceGuid === undefined || deviceGuid === null) {
        throw new Error("Missing the required parameter 'deviceGuid' when calling v2DeviceDeviceGuidDigimetaDigimetaGuidValuePost");
      }
      // verify the required parameter 'digimetaGuid' is set
      if (digimetaGuid === undefined || digimetaGuid === null) {
        throw new Error("Missing the required parameter 'digimetaGuid' when calling v2DeviceDeviceGuidDigimetaDigimetaGuidValuePost");
      }

      let pathParams = {
        'deviceGuid': deviceGuid,
        'digimetaGuid': digimetaGuid
      };
      let queryParams = {
        'auto': opts['auto'],
        'resetAs': opts['resetAs']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DigimetaOut;
      return this.apiClient.callApi(
        '/v2/device/{deviceGuid}/digimeta/{digimetaGuid}/value', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
