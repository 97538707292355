import React, { useState, useEffect } from 'react';
import { Pagination as Page } from 'react-bootstrap';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";

const Pagination = props => {
    const { t, i18n } = useTranslation();
    const {
        onPageChange,
        pageCount,
        currentPage
    } = props;
    const [key, setKey] = useState(Math.random().toString(36).substr(2, 5));
    const paginationItems = () => {
        let items = [];
        let start = (currentPage - 5) > 0 ? currentPage - 5 : 1;
        let diff = -(currentPage - 5 - start + 1);
        let end = (currentPage + 6 + diff) < pageCount ? currentPage + 6 + diff : pageCount;

        for (let number = start; number <= end; number++) {
            const isItemActive = currentPage === number - 1;
            if (number === start && start > 1) {
                items.push(
                    <Page.Ellipsis key={'key-pagination-' + Math.random().toString(36).substr(2, 5)}></Page.Ellipsis>
                )
            }
            else if (number === end && end < pageCount - 1) {
                items.push(
                    <Page.Ellipsis key={'key-pagination-' + Math.random().toString(36).substr(2, 5)}></Page.Ellipsis>
                )
            }
            else {
                items.push(<Page.Item key={'key-pagination-' + Math.random().toString(36).substr(2, 5)} active={isItemActive} onClick={() => onPageChange(Math.max(number - 1))}>{number}</Page.Item>)
            }
        };
        return items;
    }

    useEffect(() => {
        setKey(Math.random().toString(36).substr(2, 5));
    }, [currentPage])
    
    return <div className="d-flex justify-content-center w-100" key={'key-pagination-' + key} >
        <Page size="sm" className="mt-2 mb-0 flex-wrap">
            <Page.Prev onClick={() => onPageChange(0)} className={`${currentPage == 0 && 'disabled'}`}>
                {false ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : t("firstPage")}
            </Page.Prev>
            <Page.Prev onClick={() => onPageChange(Math.max(0, currentPage - 1))}  className={`${currentPage == 0 && 'disabled'}`}>
                {props.withIcons ? <FontAwesomeIcon icon={faAngleLeft} /> : t("previous")}
            </Page.Prev>
            {paginationItems()}
            <Page.Next onClick={() => onPageChange(Math.min(pageCount - 1, currentPage + 1))} className={`${currentPage == pageCount - 1 && 'disabled'}`}>
                {props.withIcons ? <FontAwesomeIcon icon={faAngleRight} /> : t("next")}
            </Page.Next>
            <Page.Next onClick={() => onPageChange(pageCount - 1)} className={`${currentPage == pageCount - 1 && 'disabled'}`}>
                {false ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : t("lastPage")}
            </Page.Next>
        </Page>
    </div>;
};

export default Pagination;