/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AlertSubscriptionOut from '../model/AlertSubscriptionOut';
import AlertSubscriptionPatchAsMember from '../model/AlertSubscriptionPatchAsMember';

/**
* V2AlertAlertGuidSubscriptionGuid service.
* @module api/V2AlertAlertGuidSubscriptionGuidApi
* @version v2
*/
export default class V2AlertAlertGuidSubscriptionGuidApi {

    /**
    * Constructs a new V2AlertAlertGuidSubscriptionGuidApi. 
    * @alias module:api/V2AlertAlertGuidSubscriptionGuidApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2AlertAlertGuidSubscriptionGuidDelete operation.
     * @callback module:api/V2AlertAlertGuidSubscriptionGuidApi~v2AlertAlertGuidSubscriptionGuidDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} alertGuid 
     * @param {String} guid 
     * @param {module:api/V2AlertAlertGuidSubscriptionGuidApi~v2AlertAlertGuidSubscriptionGuidDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    v2AlertAlertGuidSubscriptionGuidDelete(alertGuid, guid, callback) {
      let postBody = null;
      // verify the required parameter 'alertGuid' is set
      if (alertGuid === undefined || alertGuid === null) {
        throw new Error("Missing the required parameter 'alertGuid' when calling v2AlertAlertGuidSubscriptionGuidDelete");
      }
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling v2AlertAlertGuidSubscriptionGuidDelete");
      }

      let pathParams = {
        'alertGuid': alertGuid,
        'guid': guid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v2/alert/{alertGuid}/subscription/{guid}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the v2AlertAlertGuidSubscriptionGuidGet operation.
     * @callback module:api/V2AlertAlertGuidSubscriptionGuidApi~v2AlertAlertGuidSubscriptionGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AlertSubscriptionOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} alertGuid 
     * @param {String} guid 
     * @param {module:api/V2AlertAlertGuidSubscriptionGuidApi~v2AlertAlertGuidSubscriptionGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AlertSubscriptionOut}
     */
    v2AlertAlertGuidSubscriptionGuidGet(alertGuid, guid, callback) {
      let postBody = null;
      // verify the required parameter 'alertGuid' is set
      if (alertGuid === undefined || alertGuid === null) {
        throw new Error("Missing the required parameter 'alertGuid' when calling v2AlertAlertGuidSubscriptionGuidGet");
      }
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling v2AlertAlertGuidSubscriptionGuidGet");
      }

      let pathParams = {
        'alertGuid': alertGuid,
        'guid': guid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertSubscriptionOut;
      return this.apiClient.callApi(
        '/v2/alert/{alertGuid}/subscription/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the v2AlertAlertGuidSubscriptionGuidPatch operation.
     * @callback module:api/V2AlertAlertGuidSubscriptionGuidApi~v2AlertAlertGuidSubscriptionGuidPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AlertSubscriptionOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} alertGuid 
     * @param {String} guid 
     * @param {Object} opts Optional parameters
     * @param {String} opts.subscriptionGuid 
     * @param {module:model/AlertSubscriptionPatchAsMember} opts.alertSubscriptionPatchAsMember 
     * @param {module:api/V2AlertAlertGuidSubscriptionGuidApi~v2AlertAlertGuidSubscriptionGuidPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AlertSubscriptionOut}
     */
    v2AlertAlertGuidSubscriptionGuidPatch(alertGuid, guid, opts, callback) {
      opts = opts || {};
      let postBody = opts['alertSubscriptionPatchAsMember'];
      // verify the required parameter 'alertGuid' is set
      if (alertGuid === undefined || alertGuid === null) {
        throw new Error("Missing the required parameter 'alertGuid' when calling v2AlertAlertGuidSubscriptionGuidPatch");
      }
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling v2AlertAlertGuidSubscriptionGuidPatch");
      }

      let pathParams = {
        'alertGuid': alertGuid,
        'guid': guid
      };
      let queryParams = {
        'subscriptionGuid': opts['subscriptionGuid']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertSubscriptionOut;
      return this.apiClient.callApi(
        '/v2/alert/{alertGuid}/subscription/{guid}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
