/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The GeographicCoordinates model module.
 * @module model/GeographicCoordinates
 * @version v2
 */
class GeographicCoordinates {
    /**
     * Constructs a new <code>GeographicCoordinates</code>.
     * @alias module:model/GeographicCoordinates
     */
    constructor() { 
        
        GeographicCoordinates.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>GeographicCoordinates</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GeographicCoordinates} obj Optional instance to populate.
     * @return {module:model/GeographicCoordinates} The populated <code>GeographicCoordinates</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GeographicCoordinates();

            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} latitude
 */
GeographicCoordinates.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
GeographicCoordinates.prototype['longitude'] = undefined;






export default GeographicCoordinates;

