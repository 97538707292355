/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class UserType.
* @enum {}
* @readonly
*/
export default class UserType {
    
        /**
         * value: "User"
         * @const
         */
        "User" = "User";

    
        /**
         * value: "Professional"
         * @const
         */
        "Professional" = "Professional";

    
        /**
         * value: "Admin"
         * @const
         */
        "Admin" = "Admin";

    
        /**
         * value: "AccessCode"
         * @const
         */
        "AccessCode" = "AccessCode";

    
        /**
         * value: "Demo"
         * @const
         */
        "Demo" = "Demo";

    

    /**
    * Returns a <code>UserType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/UserType} The enum <code>UserType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

