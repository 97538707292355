/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CustomerOut from './CustomerOut';

/**
 * The CustomerUserDataOut model module.
 * @module model/CustomerUserDataOut
 * @version v2
 */
class CustomerUserDataOut {
    /**
     * Constructs a new <code>CustomerUserDataOut</code>.
     * @alias module:model/CustomerUserDataOut
     */
    constructor() { 
        
        CustomerUserDataOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerUserDataOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerUserDataOut} obj Optional instance to populate.
     * @return {module:model/CustomerUserDataOut} The populated <code>CustomerUserDataOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerUserDataOut();

            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('customer')) {
                obj['customer'] = CustomerOut.constructFromObject(data['customer']);
            }
        }
        return obj;
    }


}

/**
 * @member {String} type
 */
CustomerUserDataOut.prototype['type'] = undefined;

/**
 * @member {module:model/CustomerOut} customer
 */
CustomerUserDataOut.prototype['customer'] = undefined;






export default CustomerUserDataOut;

