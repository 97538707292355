import React, { useState, useEffect } from "react";
import { StyledCard } from "./style/StyledContainer";


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error:"" };
      
    }

    logErrorToMyService = (error,errorInfo) => {
      //tbd send msg to server
      console.log("log",error,errorInfo);
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.

      // tbd: get Path, Username & Error Message, bestenfalls auch die CodePosition o.ä.
      return { hasError: true, error: error };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      this.logErrorToMyService(error, errorInfo);
      this.error = error;
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
            return <StyledCard title={"Sorry! Hier ist etwas schiefgelaufen!"}><p>Das tut uns sehr leid, falls der Fehler erneut auftritt, kontaktieren Sie uns einfach telefonisch unter <a href="tel:+4951187453685">0511 8745 3685</a> oder per Mail an <a href={"mailto:service@simgaheat.de?subject=Fehler%20im%20Dashboard%20&body=Fehlermeldung:  "+this.state.error}>service@sigmaheat.de</a>.<br/><br/><br/> Vielen Dank für Ihr Verständnis!</p></StyledCard>      }
  
      return this.props.children; 
    }
  }


export default ErrorBoundary;