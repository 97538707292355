/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MeasurementAggregationEntry from './MeasurementAggregationEntry';
import MeasurementOut from './MeasurementOut';

/**
 * The MeasurementAggregationOut model module.
 * @module model/MeasurementAggregationOut
 * @version v2
 */
class MeasurementAggregationOut {
    /**
     * Constructs a new <code>MeasurementAggregationOut</code>.
     * @alias module:model/MeasurementAggregationOut
     */
    constructor() { 
        
        MeasurementAggregationOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MeasurementAggregationOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MeasurementAggregationOut} obj Optional instance to populate.
     * @return {module:model/MeasurementAggregationOut} The populated <code>MeasurementAggregationOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MeasurementAggregationOut();

            if (data.hasOwnProperty('measurement')) {
                obj['measurement'] = MeasurementOut.constructFromObject(data['measurement']);
            }
            if (data.hasOwnProperty('begin')) {
                obj['begin'] = ApiClient.convertToType(data['begin'], 'Date');
            }
            if (data.hasOwnProperty('end')) {
                obj['end'] = ApiClient.convertToType(data['end'], 'Date');
            }
            if (data.hasOwnProperty('entries')) {
                obj['entries'] = ApiClient.convertToType(data['entries'], [MeasurementAggregationEntry]);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/MeasurementOut} measurement
 */
MeasurementAggregationOut.prototype['measurement'] = undefined;

/**
 * @member {Date} begin
 */
MeasurementAggregationOut.prototype['begin'] = undefined;

/**
 * @member {Date} end
 */
MeasurementAggregationOut.prototype['end'] = undefined;

/**
 * @member {Array.<module:model/MeasurementAggregationEntry>} entries
 */
MeasurementAggregationOut.prototype['entries'] = undefined;






export default MeasurementAggregationOut;

