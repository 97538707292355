/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MeasurementAggregationEntry model module.
 * @module model/MeasurementAggregationEntry
 * @version v2
 */
class MeasurementAggregationEntry {
    /**
     * Constructs a new <code>MeasurementAggregationEntry</code>.
     * @alias module:model/MeasurementAggregationEntry
     */
    constructor() { 
        
        MeasurementAggregationEntry.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MeasurementAggregationEntry</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MeasurementAggregationEntry} obj Optional instance to populate.
     * @return {module:model/MeasurementAggregationEntry} The populated <code>MeasurementAggregationEntry</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MeasurementAggregationEntry();

            if (data.hasOwnProperty('t')) {
                obj['t'] = ApiClient.convertToType(data['t'], 'Date');
            }
            if (data.hasOwnProperty('avg')) {
                obj['avg'] = ApiClient.convertToType(data['avg'], 'Number');
            }
            if (data.hasOwnProperty('min')) {
                obj['min'] = ApiClient.convertToType(data['min'], 'Number');
            }
            if (data.hasOwnProperty('max')) {
                obj['max'] = ApiClient.convertToType(data['max'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} t
 */
MeasurementAggregationEntry.prototype['t'] = undefined;

/**
 * @member {Number} avg
 */
MeasurementAggregationEntry.prototype['avg'] = undefined;

/**
 * @member {Number} min
 */
MeasurementAggregationEntry.prototype['min'] = undefined;

/**
 * @member {Number} max
 */
MeasurementAggregationEntry.prototype['max'] = undefined;






export default MeasurementAggregationEntry;

