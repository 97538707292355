import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { ResponsiveDropDown, BrandToggle, Toggle, ProfileImage, ToggleImage, UserInformation, InformationFullName, InformationEmail, StyledNavbar } from '../style/StyledNavbar'
import { Container, Nav, Dropdown, Form } from 'react-bootstrap';
import profilePhoto from '../assets/profile.jpg';
import { LoginContext } from '../Context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CustomerSelectionDropdown = (props) => {
    const [login, setLogin, api] = useContext(LoginContext);
    const customers = login.customers;
    const currentCustomerGUID = login.currentCustomer && login.currentCustomer.customer.guid;

    const setCustomer = (newGuid) => {
        const newCustomer = customers.find(c => c.customer.guid == newGuid);
        login.currentCustomer = newCustomer;
        setLogin(login);
    };

    return <Form>
        <Form.Group id="customer">
            {/* <Form.Label>Kundenkonto</Form.Label> */}
            <Form.Select onChange={(e) => {setCustomer(e.target.value)}} value={currentCustomerGUID}>
                {customers.map((c) => <option key={'key-' + c.customer.guid} value={c.customer.guid}>{c.customer.name}</option>)}
            </Form.Select>
        </Form.Group>
    </Form>
};

const LanguageDropdown = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const languages = [
        {
            key: "de",
            name: t("german")
        },
        {
            key: "en",
            name: t("english")
        },
    ];
    
    const handleChange = (e) => {
        i18n.changeLanguage(e.target.value);
        setLanguage(e.target.value);
        localStorage.setItem("lng", e.target.value);
    }

    return <Form>
        <Form.Group id="languages">
            <Form.Select onChange={(e) => {handleChange(e)}} value={language}>
                {languages.map((lng) => <option key={`key-${lng.key}`} value={lng.key}>{lng.name}</option>)}
            </Form.Select>
        </Form.Group>
    </Form>
};

function NavBar(props) {
    const [login, setLogin, api] = useContext(LoginContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const logout = () => {
        //TODO: Revoke our own token here
        setLogin(null);
        navigate("/login");
    }

    return (
        <StyledNavbar>
            <Container fluid>
                <Nav><BrandToggle onClick={() => { props.setToggle(!props.toggle) }} /></Nav>
                <Nav>
                    <Dropdown>
                        <Toggle variant="outline-dark" id="dropdown-basic">
                            <ProfileImage src={profilePhoto} alt='profile' />
                            <UserInformation>
                                <InformationFullName>{login.user.name}</InformationFullName>
                                <InformationEmail>{login.user.email}</InformationEmail>
                            </UserInformation>
                            <ToggleImage />
                        </Toggle>
                        <ResponsiveDropDown>
                            <Dropdown.Header>{t("clientsAccount")}</Dropdown.Header>
                            <CustomerSelectionDropdown />
                            <Dropdown.Divider />
                            <Dropdown.Header>{t("changeLanguage")}</Dropdown.Header>
                            <LanguageDropdown />
                            <Dropdown.Divider />
                            <Link className='dropdown-item' to='/settings'>{t("settings")}</Link>
                            <Dropdown.Item onClick={logout}>{t("logout")}</Dropdown.Item>
                        </ResponsiveDropDown>
                    </Dropdown>
                </Nav>
            </Container>
        </StyledNavbar>
    )
}

export default NavBar