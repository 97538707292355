import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

const globalVariables = {
    fadeOut: "cubic-bezier(.8, 0, .6, 1)",
    bg: "#cecece",
    margin: "10px",
    divider: "#040404",
    btnPosition: "12px"
};

export const Container = styled.div`
    position: relative;
    max-height: 100%;
    overflow-y: auto;
    padding: .5rem;
`;

export const Sidebar = styled.div`
    position: fixed;
    width: 256px;
    height: 100%;
    top: 0;
    font-size: 120% !important;
    background-color: #FDCC78;
    z-index: 1002;
    @media (max-width: 1024px) {
        width: 100%;
        top: 60px;
        height: calc(100vh - 60px);
        transition: .3s transform ease-in-out;
        transform: ${({ isOpen }) => (!isOpen ? 'translateX(-100%)' : 'translateX(0%)')};
        z-index: 9999;
    }
`;

export const LogoContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 20px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const BrandImage = styled.img`
    max-width: 200px;
`;

export const NavLink = styled(Link)`
    display: flex;
    align-items: center;
    width: 100%;
    min-width: calc(60px - 1rem);
    height: calc(60px - 1rem);
    padding: .5rem;
    border-radius: 1rem;
    text-decoration: none;
    color: #4F4128;
    background-color: transparent;
    margin-bottom: 10px;
    &:hover {
        color: #ffffff;
    }
    span {
        display: ${({ state }) => (!state ? `none` : 'block')};
        margin-left: .5rem;
        line-height: 16px;
    }
    &:hover span {
        color: #ffffff;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    &.active {
        border: 1px solid #4F4128;
    }
`;

export const NavIcon = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: calc(60px - 1rem);
    img {
        width: 16px;
        height: 16px;
    }
`;

export const NavDivider = styled.hr`
    color: ${globalVariables.divider}
`;

export const Marker = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 28px auto;
    grid-column-gap: .5rem;
    padding: .5rem;
    margin-bottom: 10px;
    svg {
        align-self: center;
        margin: 0 auto;
    }
    span {
        align-self: center;
    }
`;