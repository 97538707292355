/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BuildingDataOut from './BuildingDataOut';
import CustomerOut from './CustomerOut';
import GeographicCoordinates from './GeographicCoordinates';

/**
 * The PropertyWithBuildingsOut model module.
 * @module model/PropertyWithBuildingsOut
 * @version v2
 */
class PropertyWithBuildingsOut {
    /**
     * Constructs a new <code>PropertyWithBuildingsOut</code>.
     * @alias module:model/PropertyWithBuildingsOut
     */
    constructor() { 
        
        PropertyWithBuildingsOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PropertyWithBuildingsOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PropertyWithBuildingsOut} obj Optional instance to populate.
     * @return {module:model/PropertyWithBuildingsOut} The populated <code>PropertyWithBuildingsOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PropertyWithBuildingsOut();

            if (data.hasOwnProperty('guid')) {
                obj['guid'] = ApiClient.convertToType(data['guid'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('owner')) {
                obj['owner'] = CustomerOut.constructFromObject(data['owner']);
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = GeographicCoordinates.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('buildings')) {
                obj['buildings'] = ApiClient.convertToType(data['buildings'], [BuildingDataOut]);
            }
        }
        return obj;
    }


}

/**
 * @member {String} guid
 */
PropertyWithBuildingsOut.prototype['guid'] = undefined;

/**
 * @member {String} name
 */
PropertyWithBuildingsOut.prototype['name'] = undefined;

/**
 * @member {module:model/CustomerOut} owner
 */
PropertyWithBuildingsOut.prototype['owner'] = undefined;

/**
 * @member {module:model/GeographicCoordinates} location
 */
PropertyWithBuildingsOut.prototype['location'] = undefined;

/**
 * @member {Array.<module:model/BuildingDataOut>} buildings
 */
PropertyWithBuildingsOut.prototype['buildings'] = undefined;






export default PropertyWithBuildingsOut;

