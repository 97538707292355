/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DeviceIn from '../model/DeviceIn';
import DeviceOut from '../model/DeviceOut';
import ProblemDetails from '../model/ProblemDetails';

/**
* V2Device service.
* @module api/V2DeviceApi
* @version v2
*/
export default class V2DeviceApi {

    /**
    * Constructs a new V2DeviceApi. 
    * @alias module:api/V2DeviceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2DeviceGet operation.
     * @callback module:api/V2DeviceApi~v2DeviceGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/DeviceOut>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/V2DeviceApi~v2DeviceGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/DeviceOut>}
     */
    v2DeviceGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [DeviceOut];
      return this.apiClient.callApi(
        '/v2/device', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the v2DevicePost operation.
     * @callback module:api/V2DeviceApi~v2DevicePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeviceOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/DeviceIn} opts.deviceIn 
     * @param {module:api/V2DeviceApi~v2DevicePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeviceOut}
     */
    v2DevicePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['deviceIn'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DeviceOut;
      return this.apiClient.callApi(
        '/v2/device', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
