/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import EventOut from '../model/EventOut';

/**
* V2UserUserGuidEventsEventGuidAck service.
* @module api/V2UserUserGuidEventsEventGuidAckApi
* @version v2
*/
export default class V2UserUserGuidEventsEventGuidAckApi {

    /**
    * Constructs a new V2UserUserGuidEventsEventGuidAckApi. 
    * @alias module:api/V2UserUserGuidEventsEventGuidAckApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2UserUserGuidEventsEventGuidAckPost operation.
     * @callback module:api/V2UserUserGuidEventsEventGuidAckApi~v2UserUserGuidEventsEventGuidAckPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EventOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} userGuid 
     * @param {String} eventGuid 
     * @param {module:api/V2UserUserGuidEventsEventGuidAckApi~v2UserUserGuidEventsEventGuidAckPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/EventOut}
     */
    v2UserUserGuidEventsEventGuidAckPost(userGuid, eventGuid, callback) {
      let postBody = null;
      // verify the required parameter 'userGuid' is set
      if (userGuid === undefined || userGuid === null) {
        throw new Error("Missing the required parameter 'userGuid' when calling v2UserUserGuidEventsEventGuidAckPost");
      }
      // verify the required parameter 'eventGuid' is set
      if (eventGuid === undefined || eventGuid === null) {
        throw new Error("Missing the required parameter 'eventGuid' when calling v2UserUserGuidEventsEventGuidAckPost");
      }

      let pathParams = {
        'userGuid': userGuid,
        'eventGuid': eventGuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EventOut;
      return this.apiClient.callApi(
        '/v2/user/{userGuid}/events/{eventGuid}/ack', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
