import React from "react";
import { Row, Col, Card, Form, Alert } from "react-bootstrap";
import styled from "styled-components";

// export const CenterRow = (props) => <Row className="justify-content-md-center" > {props.children} </Row>;
export const CenterRow = (props) => <div className="justify-content-md-center"> {props.children} </div>;

export const LargeCol = (props) => <Col xs={12} className={`${!props.last ? ' mb-4' : '' }`}> {props.children} </Col>;

export const StyledCard = (props) => <Card border="light" className={`shadow-sm${!props.last ? ' mb-4' : '' }`}>
    <Card.Body>
        <Row className='mb-2'>
            <Col className='d-flex flex-column flex-lg-row align-items-center' xs={props?.buttons ? 6 : 12}>
                <h5 className='m-0'>{props.title || ""}</h5>
            </Col>
            <Col className='d-flex flex-column flex-lg-row align-items-center justify-content-end gap-3' xs={props?.buttons ? 6 : 0}>
                { props.buttons }
            </Col>
        </Row>
        {props.children}
    </Card.Body>
</Card>;

export const Container = styled.div`
    padding: .5rem;
    @media (min-width: 1024px) {
        padding-left: calc(256px + .5rem);
    }
`;

export const AbsoluteCenter = (props) => <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '100vh', background: 'linear-gradient(to top left, #f28533,  #e0b260)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', paddingBottom: '70px'}}> {props.children} </div>;

export const FixedForm = (props) => <Form className="p-3" style={{width: "100%", maxWidth: "384px"}}> {props.children} </Form>;

export const FormAlert = (props) => <Alert className="mb-3" style={{width: "100%", maxWidth: "384px"}} variant={props.variant}> {props.children} </Alert>;

export const LoginFooter = styled.div.attrs({
        className: 'd-flex flex-wrap justify-content-between align-items-center p-3',
    })`
    position: relative;
    width: 100%;
    bottom: 0;
    height: 70px;
    border-top: 1px solid #ffffff!important;
    margin-top: -70px;
`;

export const TextFooter = styled.p.attrs({
        className: 'col-md-4 mb-0',
    })`
    font-weight: 500;
    color: #ffffff;
`;

export const ULFooter = styled.ul.attrs({
    className: 'nav col-md-4 justify-content-end',
})``;

export const LIFooter = styled.li.attrs({
    className: 'nav-item',
})``;

export const LinkFooter = styled.a.attrs({
    className: 'nav-link px-2',
})`
font-weight: 500;
    color: #ffffff;
    &:hover,
    &:focus {
        color: #ffffff;
        text-decoration: underline;
    }
    &:focus-visible {
        outline: none;
    }
`;