import {useState, useEffect, useContext} from 'react'
import {FixedForm} from '../style/StyledContainer'
import Form from 'react-bootstrap/Form'
import {SHFormLabel, SHFormEmail, SHFormButton, SHLink} from '../style/SigmaHeatTheme'
import {useTranslation} from 'react-i18next'
import {PasswordForm} from "../forms/PasswordForm";
import V2UserGuidApi from "sigma_heat_backend_api_v2/src/api/V2UserGuidApi";
import {LoginContext} from "../Context";
import {useForceUpdate} from "../util/helpers";
import {V2LoginApi, V2UserResetApi} from "sigma_heat_backend_api_v2/src";
import ApiClient from "sigma_heat_backend_api_v2/src/ApiClient";

export const Forgot = (props) => {
    const [login, setLogin, api] = useContext(LoginContext);
    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false);
    const fUpdate = useForceUpdate();
    const forceUpdate = props?.forceUpdate || fUpdate;

    if (login) {
        window.history.pushState({}, '', '/');
        forceUpdate()
    }

    const doReset = () => {
        new V2UserResetApi().v2UserResetPost({email: email}, (error, _, response) => {
            error ? setError(true) : setError(false);
            if (!error) setReset(true);
        });
    }

    if (reset) return <FixedForm>
        <h3 className='text-white'>{t('emailSend')}</h3>
        <p className='text-white'>{t('emailSendInstructions')}</p>
        <SHFormButton onClick={(e) => {
            e.preventDefault();
            window.history.pushState({}, '', '/');
            forceUpdate()
        }}>{t("returnLogin")}</SHFormButton>
    </FixedForm>

    return (
        <FixedForm>
            <h3 className='text-white'>{t("resetYourPassword")}</h3>
            <p className='text-white'>{t("resetInstructions")}</p>
            <Form.Group className='mb-3' controlId='formEmail'>
                <SHFormLabel>E-Mail</SHFormLabel>
                <SHFormEmail placeholder='beispiel@sigmaheat.de' value={email} onChange={(e) => {
                    setEmail(e.target.value)
                }}/>
            </Form.Group>
            <div className='d-flex justify-content-between align-items-center gap-3'>
                <SHFormButton type='submit' onClick={(e) => {
                    e.preventDefault();
                    doReset();
                }}>{t("resetPassword")}</SHFormButton>
                <SHLink href='#' onClick={(e) => {
                    e.preventDefault();
                    window.history.pushState({}, '', '/');
                    forceUpdate()
                }}>{t("returnLogin")}</SHLink>
            </div>
        </FixedForm>
    )
}

export const Reset = (props) => {
    const [login, setLogin] = useContext(LoginContext);
    const {t, i18n} = useTranslation();
    const [user, setUser] = useState(null);
    const [pwChanged, setPwChanged] = useState(false);

    const query = window.location.search
    const searchParams = new URLSearchParams(query);
    const token = searchParams.get('token');

    

    if ((login || !token) && !pwChanged) window.location.replace('/');

    const api = new ApiClient();
    api.authentications['Bearer'] = {
        ...api.authentications['Bearer'],
        accessToken: token
    };
    api.defaultHeaders = [];

    const changePassword = (pass) => {
        if (!pass || !user) return;
        new V2UserGuidApi(api).v2UserGuidPatch(user.guid, {userPatch: {password: pass}}, (error, data, response) => {
            if (!data || error) return;
            setPwChanged(true);
        })
    }

    useEffect(() => {
        new V2LoginApi(api).v2LoginGet((error, login, response) => {
            if (!error) setUser(login.user);
            if (error) alert("Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support unter support@sigmaheat.de");
        });
    }, [])

    if (pwChanged) return <FixedForm>
        <p className='text-white'>{t('passwordChanged')}</p>
        <SHFormButton onClick={(e) => {
            e.preventDefault();
            window.location.replace('/');
        }}>{t("returnLogin")}</SHFormButton>
    </FixedForm>

    return (
        <div className='p-3' style={{width: '100%', maxWidth: '512px'}}>
            <PasswordForm onSubmit={((password) => {
                if (password) {
                    changePassword(password);
                }
            })}></PasswordForm>
        </div>
    )
}