import React, { useContext } from "react";
import { Container, Sidebar, NavLink, NavIcon, NavDivider, LogoContainer, BrandImage, Marker } from "../style/StyledSidebar";
import { Nav } from "react-bootstrap";
import {
    faBell,
    faCameraRetro,
    faGears,
    faHouse,
    faShieldHalved,
    faCity,
    faCartShopping,
    faPowerOff,
    faUser,
    faUpload,
    faObjectGroup,
    faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../assets/logo.png'
import { LoginContext } from "../Context";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default (props) => {
    const [login, setLogin, api] = useContext(LoginContext);
    const currentRoute = useLocation();
    const { t, i18n } = useTranslation();

    const linksArray = [
        {
            label: t("homePage"),
            icon: faHouse,
            to: "/"
        },
        {
            label: t("properties"),
            icon: faCity,
            to: "/properties",
            subto: "/buildings"
        },
        {
            label: t("alarms"),
            icon: faBell,
            to: "/alarms"
        },
        {
            label: "DigiMeta",
            icon: faCameraRetro,
            to: "/digimeta"
        },
        {
            label: t("company"),
            icon: faGears,
            to: "/company"
        }
    ];
    const adminArray = login.user.type === "Admin" && [
        {
            label: t("clients"),
            icon: faLayerGroup,
            to: "/admin/customer"
        },
        {
            label: t("users"),
            icon: faUser,
            to: "/admin/users"
        },
        {
            label: t("devices"),
            icon: faPowerOff,
            to: "/admin/devices"
        },
        {
            label: "DigiMeta",
            icon: faPowerOff,
            to: "/admin/digimeta"
        },
        {
            label: "Leads",
            icon: faCartShopping,
            to: "/admin/leads"
        },
        {
            label: "Bulk-Upload",
            icon: faUpload,
            to: "/admin/bulk"
        },

    ];

    const SidebarElement = ({ icon, label, to, subto }) => {
        const substring = subto ? currentRoute.pathname.includes(subto) : false;

        return (
            <NavLink to={to} className={`${substring && 'active'}`} onClick={() => props.setToggle(false)} state={true} key={"key-" + to}>
                <NavIcon>
                    <FontAwesomeIcon icon={icon} />
                </NavIcon>
                <span>{label}</span>
            </NavLink>
        )
    };

    return <Sidebar isOpen={props.isopen}>
        <Container className="muti">
            <LogoContainer>
                <Link to='/'>
                    <BrandImage src={logo} alt='SigmaHeat' />
                </Link>
            </LogoContainer>

            <Nav variant="pills" className="flex-column">
                {linksArray.map(SidebarElement)}
            </Nav>
            {
                adminArray && <>
                    <NavDivider />
                    <Marker>
                        <FontAwesomeIcon icon={faShieldHalved}></FontAwesomeIcon>
                        <span>{t("administrationArea")}</span>
                    </Marker>
                    <Nav variant="pills" className="flex-column">
                        {adminArray.map(SidebarElement)}
                    </Nav>
                </>
            }
        </Container>
    </Sidebar>;
}