/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class Quantity.
* @enum {}
* @readonly
*/
export default class Quantity {
    
        /**
         * value: "Temperature"
         * @const
         */
        "Temperature" = "Temperature";

    
        /**
         * value: "Energy"
         * @const
         */
        "Energy" = "Energy";

    
        /**
         * value: "Power"
         * @const
         */
        "Power" = "Power";

    
        /**
         * value: "Time"
         * @const
         */
        "Time" = "Time";

    
        /**
         * value: "Flow"
         * @const
         */
        "Flow" = "Flow";

    
        /**
         * value: "Volume"
         * @const
         */
        "Volume" = "Volume";

    
        /**
         * value: "Voltage"
         * @const
         */
        "Voltage" = "Voltage";

    
        /**
         * value: "Current"
         * @const
         */
        "Current" = "Current";

    
        /**
         * value: "TemperatureDifference"
         * @const
         */
        "TemperatureDifference" = "TemperatureDifference";

    
        /**
         * value: "Dimensionless"
         * @const
         */
        "Dimensionless" = "Dimensionless";

    
        /**
         * value: "Other"
         * @const
         */
        "Other" = "Other";

    

    /**
    * Returns a <code>Quantity</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/Quantity} The enum <code>Quantity</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

