/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import RxInfo from './RxInfo';

/**
 * The LorawanUpload model module.
 * @module model/LorawanUpload
 * @version v2
 */
class LorawanUpload {
    /**
     * Constructs a new <code>LorawanUpload</code>.
     * @alias module:model/LorawanUpload
     */
    constructor() { 
        
        LorawanUpload.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LorawanUpload</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LorawanUpload} obj Optional instance to populate.
     * @return {module:model/LorawanUpload} The populated <code>LorawanUpload</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LorawanUpload();

            if (data.hasOwnProperty('data')) {
                obj['data'] = ApiClient.convertToType(data['data'], 'String');
            }
            if (data.hasOwnProperty('devEUI')) {
                obj['devEUI'] = ApiClient.convertToType(data['devEUI'], 'String');
            }
            if (data.hasOwnProperty('deviceName')) {
                obj['deviceName'] = ApiClient.convertToType(data['deviceName'], 'String');
            }
            if (data.hasOwnProperty('applicationName')) {
                obj['applicationName'] = ApiClient.convertToType(data['applicationName'], 'String');
            }
            if (data.hasOwnProperty('rxinfo')) {
                obj['rxinfo'] = ApiClient.convertToType(data['rxinfo'], [RxInfo]);
            }
        }
        return obj;
    }


}

/**
 * @member {String} data
 */
LorawanUpload.prototype['data'] = undefined;

/**
 * @member {String} devEUI
 */
LorawanUpload.prototype['devEUI'] = undefined;

/**
 * @member {String} deviceName
 */
LorawanUpload.prototype['deviceName'] = undefined;

/**
 * @member {String} applicationName
 */
LorawanUpload.prototype['applicationName'] = undefined;

/**
 * @member {Array.<module:model/RxInfo>} rxinfo
 */
LorawanUpload.prototype['rxinfo'] = undefined;






export default LorawanUpload;

