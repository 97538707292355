import React, {useState, useEffect, useMemo, lazy, Suspense} from "react";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import Login from "./login/Login";
import './stylesheet.css';
import {LoginContext} from "./Context";
import {Navigate} from "react-router";
import ApiClient from "sigma_heat_backend_api_v2/src/ApiClient";
import PlantListPage from "./plants/Plants";
// import AdminDevices from "./admin/AdminDevices";
import AdminDigimeta from "./admin/AdminDigimeta";
import NavBar from "./widgets/Navbar";
import Sidebar from "./widgets/Sidebar";
import Plant from "./plants/Plant";
import {Container} from "./style/StyledContainer";
import CookieConsent, {Cookies} from "react-cookie-consent";
import {checkProfile, getSavedProfile, isUUID, useForceUpdate, verifyLocalStorage} from "./util/helpers";
import V2LoginApi from "sigma_heat_backend_api_v2/src/api/V2LoginApi";
import {useTranslation} from 'react-i18next';
import ErrorBoundary from "./ErrorBoundary";
import ReactDOM from 'react-dom';
import localStorageVersion from './util/constants/LocalStorageVersion'
import _ from "lodash";

const AdminUsers = lazy(() => import('./admin/AdminUsers'));
const AdminDevices = lazy(() => import('./admin/AdminDevices'));
const AdminLeads = lazy(() => import('./leads/AdminLeads'));
const AdminBulkUpload = lazy(() => import('./admin/bulk/AdminBulkUpload'));
const AdminCustomer = lazy(() => import('./admin/customer/AdminCustomer'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Alarms = lazy(() => import('./alarms/Alarms'));
const Settings = lazy(() => import('./settings/Settings'));
const Digimeta = lazy(() => import('./digimeta/Digimeta'));
const Properties = lazy(() => import('./properties/Properties'));
const CompanyProfile = lazy(() => import('./companyProfile/CompanyProfile'));
const BuildingPageWidget = lazy(() => import('./buildings/BuildingPageWidget'));


function NavSidebar() {
    const [isOpen, setIsOpen] = useState(true);
    const [toggle, setToggle] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsOpen(window.innerWidth > 1024);
        }

        handleResize();

        window.addEventListener('resize', handleResize);
    }, []);

    return <>
        <Sidebar isopen={isOpen || toggle} setToggle={setToggle} />
        <NavBar {...{toggle, setToggle}} />
    </>
}

function App() {
    const [_login, _setLogin] = useState(getSavedProfile());

    const api = useMemo(() => {
        const api = new ApiClient();
        if (_login != null) api.authentications['Bearer'] = {
            ...api.authentications['Bearer'],
            accessToken: _login.token
        };
        api.defaultHeaders = [];    //Do not set UserAgent in the Browser, mmk?
        return api;
    }, [_login]);

    const {t, i18n} = useTranslation();
    const forceUpdate = useForceUpdate();
    const _ = require('lodash');

    const updateProfile = () => {
        new V2LoginApi(api).v2LoginGet((error, data, response) => {
            if (error == null) {
                doSetLogin(data);
            }
        });
    };

    //TODO use useEffect to load login data
    useEffect(() => {
        updateProfile();
    }, [window.location.pathname]);

    const doSetLogin = (login) => {
        console.log("doSetLogin", login)
        //Do not save an invalid or expired token
        if(!checkProfile(login)){
            console.log("_setLogin", null)
            localStorage.setItem('Profile', null);
            _setLogin(null);
            return;
        }

        let old = localStorage.getItem('Profile');
        old = JSON.parse(old);
        login = JSON.parse(JSON.stringify(login));

        if (old && old.currentCustomer && !login.currentCustomer) login.currentCustomer = login.customers.find(c => c.customer.guid === old.currentCustomer.customer.guid);

        if (!old
            || !_.isEqual(old.user, login.user)
            || !_.isEqual(old.token, login.token)
            || !_.isEqual(old.currentCustomer, login.currentCustomer)
            || !_.isEqual(old.customers, login.customers)) {
            if (login.currentCustomer == null) login.currentCustomer = login.customers[0];
            console.log("_setLogin", login)
            _setLogin(login);
            localStorage.setItem('Profile', JSON.stringify(login));
            //forceUpdate();
        }
    }

    if (_login == null) {
        return <LoginContext.Provider value={[null, doSetLogin, null]}>
            <Login/>
            <CookieConsent
                location="bottom"
                buttonText="Akzeptieren"
                style={{background: "#2B373B"}}
                buttonStyle={{background: '#f67d1f', color: "#ffffff", fontSize: "14px"}}
                expires={150}
                overlay
            >
                {t("cookiesFirstSentence")} <a style={{textDecoration: "underline", color: '#fff'}}
                                               href='https://sigmaheat.de/datenschutz'
                                               target='_blank'>{i18n.language === 'de' ? 'Hier' : 'Here'}</a> {t("cookiesSecondSentence")}
            </CookieConsent>
        </LoginContext.Provider>
    }

    const defaultRoute = "/";

    const WithUI = (props) => {
        // Detect refresh or Change of Location
        const location = useLocation();
        useEffect(() => {
            if (new Date(_login.validUntil) <= new Date()) {
                doSetLogin(null);
            }
        }, [location]);


        return <>
            <NavSidebar />
            
            <Container>
                <ErrorBoundary key={Math.random()}>
                    {props.children}
                </ErrorBoundary>
            </Container>

            <CookieConsent
                location="bottom"
                buttonText="Akzeptieren"
                style={{background: "#2B373B"}}
                buttonStyle={{background: '#f67d1f', color: "#ffffff", fontSize: "14px"}}
                expires={150}
                overlay
            >
                {t("cookiesFirstSentence")} <a style={{textDecoration: "underline", color: '#fff'}}
                                               href='https://sigmaheat.de/datenschutz'
                                               target='_blank'>{i18n.language === 'de' ? 'Hier' : 'Here'}</a> {t("cookiesSecondSentence")}
            </CookieConsent>
        </>;
    }

    return (
        <LoginContext.Provider value={[_login, doSetLogin, api]}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<WithUI><Suspense fallback={<p>Loading...</p>}><Dashboard /></Suspense></WithUI>}/>
                    <Route path="buildings/:buildingGuid" element={<WithUI><Suspense fallback={<p>Loading...</p>}><BuildingPageWidget /></Suspense></WithUI>}/>
                    {/* <Route path="plants/:plantGuid" element={<WithUI><Plant/></WithUI>}/>
                    <Route path="plants" element={<WithUI><PlantListPage/></WithUI>}/>*/}
                    <Route path="/alarms" element={<WithUI><Suspense fallback={<p>Loading...</p>}><Alarms/></Suspense></WithUI>}/>
                    <Route path="/digimeta" element={<WithUI><Suspense fallback={<p>Loading...</p>}><Digimeta/></Suspense></WithUI>}/>
                    <Route path="/properties" element={<WithUI><Suspense fallback={<p>Loading...</p>}><Properties/></Suspense></WithUI>}/>
                    <Route path="/settings" element={<WithUI><Suspense fallback={<p>Loading...</p>}><Settings/></Suspense></WithUI>}/>
                    <Route path="/company" element={<WithUI><Suspense fallback={<p>Loading...</p>}><CompanyProfile updateProfile={updateProfile}/></Suspense></WithUI>}/>

                    <Route path="/admin/customer" element={<WithUI><Suspense fallback={<p>Loading...</p>}><AdminCustomer/></Suspense></WithUI>}/>
                    <Route path="/admin/digimeta" element={<WithUI><Suspense fallback={<p>Loading...</p>}><Digimeta admin={true}/></Suspense></WithUI>}/>
                    <Route path="/admin/users" element={<WithUI><Suspense fallback={<p>Loading...</p>}><AdminUsers/></Suspense></WithUI>}/>
                    <Route path="/admin/devices" element={<WithUI><Suspense fallback={<p>Loading...</p>}><AdminDevices/></Suspense></WithUI>}/>
                    <Route path="/admin/leads" element={<WithUI><Suspense fallback={<p>Loading...</p>}><AdminLeads/></Suspense></WithUI>}/>
                    <Route path="/admin/bulk" element={<WithUI><Suspense fallback={<p>Loading...</p>}><AdminBulkUpload/></Suspense></WithUI>}/>

                    <Route path="*" element={<Navigate to={defaultRoute} replace/>}/>
                </Routes>
            </BrowserRouter>
        </LoginContext.Provider>
    );
}

export default App;