/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ProblemDetails from '../model/ProblemDetails';
import UserIn from '../model/UserIn';
import UserOut from '../model/UserOut';

/**
* V2User service.
* @module api/V2UserApi
* @version v2
*/
export default class V2UserApi {

    /**
    * Constructs a new V2UserApi. 
    * @alias module:api/V2UserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2UserGet operation.
     * @callback module:api/V2UserApi~v2UserGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/UserOut>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a list of all users
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit  (default to 2147483647)
     * @param {Number} opts.offset  (default to 0)
     * @param {module:api/V2UserApi~v2UserGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/UserOut>}
     */
    v2UserGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [UserOut];
      return this.apiClient.callApi(
        '/v2/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the v2UserPost operation.
     * @callback module:api/V2UserApi~v2UserPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/UserIn} opts.userIn 
     * @param {module:api/V2UserApi~v2UserPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserOut}
     */
    v2UserPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['userIn'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserOut;
      return this.apiClient.callApi(
        '/v2/user', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
