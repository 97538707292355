/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AlertSubscriptionType from './AlertSubscriptionType';
import UserOut from './UserOut';

/**
 * The AlertSubscriptionOut model module.
 * @module model/AlertSubscriptionOut
 * @version v2
 */
class AlertSubscriptionOut {
    /**
     * Constructs a new <code>AlertSubscriptionOut</code>.
     * @alias module:model/AlertSubscriptionOut
     */
    constructor() { 
        
        AlertSubscriptionOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AlertSubscriptionOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AlertSubscriptionOut} obj Optional instance to populate.
     * @return {module:model/AlertSubscriptionOut} The populated <code>AlertSubscriptionOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AlertSubscriptionOut();

            if (data.hasOwnProperty('guid')) {
                obj['guid'] = ApiClient.convertToType(data['guid'], 'String');
            }
            if (data.hasOwnProperty('alertGuid')) {
                obj['alertGuid'] = ApiClient.convertToType(data['alertGuid'], 'String');
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = UserOut.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = AlertSubscriptionType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('destination')) {
                obj['destination'] = ApiClient.convertToType(data['destination'], 'String');
            }
            if (data.hasOwnProperty('limitHorizonMillis')) {
                obj['limitHorizonMillis'] = ApiClient.convertToType(data['limitHorizonMillis'], 'Number');
            }
            if (data.hasOwnProperty('limitCount')) {
                obj['limitCount'] = ApiClient.convertToType(data['limitCount'], 'Number');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('activeFrom')) {
                obj['activeFrom'] = ApiClient.convertToType(data['activeFrom'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {String} guid
 */
AlertSubscriptionOut.prototype['guid'] = undefined;

/**
 * @member {String} alertGuid
 */
AlertSubscriptionOut.prototype['alertGuid'] = undefined;

/**
 * @member {module:model/UserOut} user
 */
AlertSubscriptionOut.prototype['user'] = undefined;

/**
 * @member {module:model/AlertSubscriptionType} type
 */
AlertSubscriptionOut.prototype['type'] = undefined;

/**
 * @member {String} destination
 */
AlertSubscriptionOut.prototype['destination'] = undefined;

/**
 * @member {Number} limitHorizonMillis
 */
AlertSubscriptionOut.prototype['limitHorizonMillis'] = undefined;

/**
 * @member {Number} limitCount
 */
AlertSubscriptionOut.prototype['limitCount'] = undefined;

/**
 * @member {Date} createdAt
 */
AlertSubscriptionOut.prototype['createdAt'] = undefined;

/**
 * @member {Date} activeFrom
 */
AlertSubscriptionOut.prototype['activeFrom'] = undefined;






export default AlertSubscriptionOut;

