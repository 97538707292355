/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import GeographicCoordinates from './GeographicCoordinates';

/**
 * The PlantPatch model module.
 * @module model/PlantPatch
 * @version v2
 */
class PlantPatch {
    /**
     * Constructs a new <code>PlantPatch</code>.
     * @alias module:model/PlantPatch
     */
    constructor() { 
        
        PlantPatch.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PlantPatch</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PlantPatch} obj Optional instance to populate.
     * @return {module:model/PlantPatch} The populated <code>PlantPatch</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PlantPatch();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('plantTypeGuid')) {
                obj['plantTypeGuid'] = ApiClient.convertToType(data['plantTypeGuid'], 'String');
            }
            if (data.hasOwnProperty('buildingGuid')) {
                obj['buildingGuid'] = ApiClient.convertToType(data['buildingGuid'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = GeographicCoordinates.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('ownerGuids')) {
                obj['ownerGuids'] = ApiClient.convertToType(data['ownerGuids'], ['String']);
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
PlantPatch.prototype['name'] = undefined;

/**
 * @member {String} plantTypeGuid
 */
PlantPatch.prototype['plantTypeGuid'] = undefined;

/**
 * @member {String} buildingGuid
 */
PlantPatch.prototype['buildingGuid'] = undefined;

/**
 * @member {module:model/GeographicCoordinates} location
 */
PlantPatch.prototype['location'] = undefined;

/**
 * @member {Array.<String>} ownerGuids
 */
PlantPatch.prototype['ownerGuids'] = undefined;

/**
 * @member {String} comment
 */
PlantPatch.prototype['comment'] = undefined;






export default PlantPatch;

