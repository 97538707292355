/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserOut from './UserOut';

/**
 * The ImageOut model module.
 * @module model/ImageOut
 * @version v2
 */
class ImageOut {
    /**
     * Constructs a new <code>ImageOut</code>.
     * @alias module:model/ImageOut
     */
    constructor() { 
        
        ImageOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ImageOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ImageOut} obj Optional instance to populate.
     * @return {module:model/ImageOut} The populated <code>ImageOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ImageOut();

            if (data.hasOwnProperty('guid')) {
                obj['guid'] = ApiClient.convertToType(data['guid'], 'String');
            }
            if (data.hasOwnProperty('extension')) {
                obj['extension'] = ApiClient.convertToType(data['extension'], 'String');
            }
            if (data.hasOwnProperty('uploadedAt')) {
                obj['uploadedAt'] = ApiClient.convertToType(data['uploadedAt'], 'Date');
            }
            if (data.hasOwnProperty('uploader')) {
                obj['uploader'] = UserOut.constructFromObject(data['uploader']);
            }
        }
        return obj;
    }


}

/**
 * @member {String} guid
 */
ImageOut.prototype['guid'] = undefined;

/**
 * @member {String} extension
 */
ImageOut.prototype['extension'] = undefined;

/**
 * @member {Date} uploadedAt
 */
ImageOut.prototype['uploadedAt'] = undefined;

/**
 * @member {module:model/UserOut} uploader
 */
ImageOut.prototype['uploader'] = undefined;






export default ImageOut;

