/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DeviceVersionOut model module.
 * @module model/DeviceVersionOut
 * @version v2
 */
class DeviceVersionOut {
    /**
     * Constructs a new <code>DeviceVersionOut</code>.
     * @alias module:model/DeviceVersionOut
     */
    constructor() { 
        
        DeviceVersionOut.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DeviceVersionOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeviceVersionOut} obj Optional instance to populate.
     * @return {module:model/DeviceVersionOut} The populated <code>DeviceVersionOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeviceVersionOut();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('hardwareVersion')) {
                obj['hardwareVersion'] = ApiClient.convertToType(data['hardwareVersion'], 'String');
            }
            if (data.hasOwnProperty('sensorCount')) {
                obj['sensorCount'] = ApiClient.convertToType(data['sensorCount'], 'Number');
            }
            if (data.hasOwnProperty('sensors')) {
                obj['sensors'] = ApiClient.convertToType(data['sensors'], {'String': 'Number'});
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
DeviceVersionOut.prototype['name'] = undefined;

/**
 * @member {String} description
 */
DeviceVersionOut.prototype['description'] = undefined;

/**
 * @member {String} hardwareVersion
 */
DeviceVersionOut.prototype['hardwareVersion'] = undefined;

/**
 * @member {Number} sensorCount
 */
DeviceVersionOut.prototype['sensorCount'] = undefined;

/**
 * @member {Object.<String, Number>} sensors
 */
DeviceVersionOut.prototype['sensors'] = undefined;






export default DeviceVersionOut;

