/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CustomerUserOut from '../model/CustomerUserOut';

/**
* V2CustomerCustomerGuidUsersUserGuidCuTypeGuid service.
* @module api/V2CustomerCustomerGuidUsersUserGuidCuTypeGuidApi
* @version v2
*/
export default class V2CustomerCustomerGuidUsersUserGuidCuTypeGuidApi {

    /**
    * Constructs a new V2CustomerCustomerGuidUsersUserGuidCuTypeGuidApi. 
    * @alias module:api/V2CustomerCustomerGuidUsersUserGuidCuTypeGuidApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2CustomerCustomerGuidUsersUserGuidCuTypeGuidPut operation.
     * @callback module:api/V2CustomerCustomerGuidUsersUserGuidCuTypeGuidApi~v2CustomerCustomerGuidUsersUserGuidCuTypeGuidPutCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/CustomerUserOut>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} customerGuid 
     * @param {String} userGuid 
     * @param {String} cuTypeGuid 
     * @param {module:api/V2CustomerCustomerGuidUsersUserGuidCuTypeGuidApi~v2CustomerCustomerGuidUsersUserGuidCuTypeGuidPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/CustomerUserOut>}
     */
    v2CustomerCustomerGuidUsersUserGuidCuTypeGuidPut(customerGuid, userGuid, cuTypeGuid, callback) {
      let postBody = null;
      // verify the required parameter 'customerGuid' is set
      if (customerGuid === undefined || customerGuid === null) {
        throw new Error("Missing the required parameter 'customerGuid' when calling v2CustomerCustomerGuidUsersUserGuidCuTypeGuidPut");
      }
      // verify the required parameter 'userGuid' is set
      if (userGuid === undefined || userGuid === null) {
        throw new Error("Missing the required parameter 'userGuid' when calling v2CustomerCustomerGuidUsersUserGuidCuTypeGuidPut");
      }
      // verify the required parameter 'cuTypeGuid' is set
      if (cuTypeGuid === undefined || cuTypeGuid === null) {
        throw new Error("Missing the required parameter 'cuTypeGuid' when calling v2CustomerCustomerGuidUsersUserGuidCuTypeGuidPut");
      }

      let pathParams = {
        'customerGuid': customerGuid,
        'userGuid': userGuid,
        'cuTypeGuid': cuTypeGuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [CustomerUserOut];
      return this.apiClient.callApi(
        '/v2/customer/{customerGuid}/users/{userGuid}/{cuTypeGuid}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
