import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { SHButton } from '../style/SigmaHeatTheme';

export const ConfirmationModal = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <Modal size='sm' as={Modal.Dialog} centered show={props.show} onHide={() => {props.onClose(false)}}>
            <Modal.Header>
                <Modal.Title className="h4">{t("confirm")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={() => {props.onClose(false)}} />
            </Modal.Header>
            <h5 className="p-4">{props.text.split('\n').map((string, index) => <React.Fragment key={`key-${index}`}>{index !== 0 && <br />}{string}</React.Fragment>)}</h5>
            <Modal.Footer className="d-flex justify-content-between">
                <SHButton onClick={() => {props.onClose(true)}}>{t("confirm")}</SHButton>
                <SHButton onClick={() => {props.onClose(false)}}>{t("cancel")}</SHButton>
            </Modal.Footer>
        </Modal>
    );
};