/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* V2CustomerCustomerGuidUsersUserGuid service.
* @module api/V2CustomerCustomerGuidUsersUserGuidApi
* @version v2
*/
export default class V2CustomerCustomerGuidUsersUserGuidApi {

    /**
    * Constructs a new V2CustomerCustomerGuidUsersUserGuidApi. 
    * @alias module:api/V2CustomerCustomerGuidUsersUserGuidApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2CustomerCustomerGuidUsersUserGuidDelete operation.
     * @callback module:api/V2CustomerCustomerGuidUsersUserGuidApi~v2CustomerCustomerGuidUsersUserGuidDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} customerGuid 
     * @param {String} userGuid 
     * @param {module:api/V2CustomerCustomerGuidUsersUserGuidApi~v2CustomerCustomerGuidUsersUserGuidDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    v2CustomerCustomerGuidUsersUserGuidDelete(customerGuid, userGuid, callback) {
      let postBody = null;
      // verify the required parameter 'customerGuid' is set
      if (customerGuid === undefined || customerGuid === null) {
        throw new Error("Missing the required parameter 'customerGuid' when calling v2CustomerCustomerGuidUsersUserGuidDelete");
      }
      // verify the required parameter 'userGuid' is set
      if (userGuid === undefined || userGuid === null) {
        throw new Error("Missing the required parameter 'userGuid' when calling v2CustomerCustomerGuidUsersUserGuidDelete");
      }

      let pathParams = {
        'customerGuid': customerGuid,
        'userGuid': userGuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v2/customer/{customerGuid}/users/{userGuid}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
