/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AlertInMeasurement from '../model/AlertInMeasurement';
import AlertOut from '../model/AlertOut';

/**
* V2MeasurementMeasurementGuidAlerts service.
* @module api/V2MeasurementMeasurementGuidAlertsApi
* @version v2
*/
export default class V2MeasurementMeasurementGuidAlertsApi {

    /**
    * Constructs a new V2MeasurementMeasurementGuidAlertsApi. 
    * @alias module:api/V2MeasurementMeasurementGuidAlertsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2MeasurementMeasurementGuidAlertsGet operation.
     * @callback module:api/V2MeasurementMeasurementGuidAlertsApi~v2MeasurementMeasurementGuidAlertsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AlertOut>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} measurementGuid 
     * @param {module:api/V2MeasurementMeasurementGuidAlertsApi~v2MeasurementMeasurementGuidAlertsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AlertOut>}
     */
    v2MeasurementMeasurementGuidAlertsGet(measurementGuid, callback) {
      let postBody = null;
      // verify the required parameter 'measurementGuid' is set
      if (measurementGuid === undefined || measurementGuid === null) {
        throw new Error("Missing the required parameter 'measurementGuid' when calling v2MeasurementMeasurementGuidAlertsGet");
      }

      let pathParams = {
        'measurementGuid': measurementGuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [AlertOut];
      return this.apiClient.callApi(
        '/v2/measurement/{measurementGuid}/alerts', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the v2MeasurementMeasurementGuidAlertsPost operation.
     * @callback module:api/V2MeasurementMeasurementGuidAlertsApi~v2MeasurementMeasurementGuidAlertsPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AlertOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} measurementGuid 
     * @param {Object} opts Optional parameters
     * @param {module:model/AlertInMeasurement} opts.alertInMeasurement 
     * @param {module:api/V2MeasurementMeasurementGuidAlertsApi~v2MeasurementMeasurementGuidAlertsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AlertOut}
     */
    v2MeasurementMeasurementGuidAlertsPost(measurementGuid, opts, callback) {
      opts = opts || {};
      let postBody = opts['alertInMeasurement'];
      // verify the required parameter 'measurementGuid' is set
      if (measurementGuid === undefined || measurementGuid === null) {
        throw new Error("Missing the required parameter 'measurementGuid' when calling v2MeasurementMeasurementGuidAlertsPost");
      }

      let pathParams = {
        'measurementGuid': measurementGuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertOut;
      return this.apiClient.callApi(
        '/v2/measurement/{measurementGuid}/alerts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
