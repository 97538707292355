/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class OwnedObjectType.
* @enum {}
* @readonly
*/
export default class OwnedObjectType {
    
        /**
         * value: "Alert"
         * @const
         */
        "Alert" = "Alert";

    
        /**
         * value: "Subscription"
         * @const
         */
        "Subscription" = "Subscription";

    
        /**
         * value: "Building"
         * @const
         */
        "Building" = "Building";

    
        /**
         * value: "Device"
         * @const
         */
        "Device" = "Device";

    
        /**
         * value: "DeviceVersion"
         * @const
         */
        "DeviceVersion" = "DeviceVersion";

    
        /**
         * value: "Event"
         * @const
         */
        "Event" = "Event";

    
        /**
         * value: "Measurement"
         * @const
         */
        "Measurement" = "Measurement";

    
        /**
         * value: "Plant"
         * @const
         */
        "Plant" = "Plant";

    
        /**
         * value: "PlantType"
         * @const
         */
        "PlantType" = "PlantType";

    
        /**
         * value: "Plot"
         * @const
         */
        "Plot" = "Plot";

    
        /**
         * value: "SystemPoint"
         * @const
         */
        "SystemPoint" = "SystemPoint";

    
        /**
         * value: "User"
         * @const
         */
        "User" = "User";

    
        /**
         * value: "MaintenanceBook"
         * @const
         */
        "MaintenanceBook" = "MaintenanceBook";

    
        /**
         * value: "Image"
         * @const
         */
        "Image" = "Image";

    
        /**
         * value: "Customer"
         * @const
         */
        "Customer" = "Customer";

    
        /**
         * value: "CustomerType"
         * @const
         */
        "CustomerType" = "CustomerType";

    
        /**
         * value: "Property"
         * @const
         */
        "Property" = "Property";

    

    /**
    * Returns a <code>OwnedObjectType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/OwnedObjectType} The enum <code>OwnedObjectType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

