import Quantity from "sigma_heat_backend_api_v2/src/model/Quantity";
import generator from "generate-password";
import {useState} from "react";
import localStorageVersion from "./constants/LocalStorageVersion";

export function generatePassword() {
    return generator.generate({
        length: 15,
        lowercase: true,
        uppercase: true,
        numbers: true,
        symbols: false
    });
}

const style = getComputedStyle(document.body);
export const chartColors = (() => {
    let colors = [
        style.getPropertyValue('--bs-primary').trim(),
        style.getPropertyValue('--bs-secondary').trim(),
        style.getPropertyValue('--bs-tertiary').trim(),
    ];

    colors.forEach((value, index) => {
        if (value.includes('#')) {
            function hexToRgbA(hex) {
                var c;
                if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                    c = hex.substring(1).split('');
                    if (c.length === 3) {
                        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                    }
                    c = '0x' + c.join('');
                    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
                }
                throw new Error('Bad Hex');
            }

            colors[index] = hexToRgbA(value);
        } else if (value.includes('rgb') && !value.includes('rgba')) {
            colors[index] = value.replace('rgb', 'rgba');
            colors[index] = colors[index].slice(0, colors[index].length - 1).concat(', 1)');
            console.log(colors[index]);

        }
    });

    return colors;
})();

export function getBreakpoint() {
    for (let item of document.getElementById('bootstrap-detect-breakpoint').children) {
        if (getComputedStyle(item, null).display === 'block')
            return item.getAttribute('bp');
    }
}

export function addressToString(address) {
    let string = `${(address.street && address.street + ', ')}${(address.zip && address.zip)} ${(address.town && address.town)}`;
    return string;

}

export function numberToString(x) {
    if (Number.isNaN(Number.parseFloat(x))) return x;
    return Number.parseFloat(x).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2});
}

export function quantityToUnit(quantity) {
    switch (Quantity.constructFromObject(quantity)) {
        case "Temperature":
            return "°C";
        case "Energy":
            return "kWh";
        case "Power":
            return "kW";
        case "Time":
            return "h";
        case "Flow":
            return "m³/h";
        case "Volume":
            return "m³";
        case "Voltage":
            return "V";
        case "Current":
            return "A";
        case "TemperatureDifference":
            return "K";
    }
    return quantity;
}


export function quantityName(quantity) {
    switch (quantity) {
        case "Temperature":
            return "Temperatur [℃]";
        case "Energy":
            return "Energie [kWh]";
        case "Power":
            return "Leistung [kW]";
        case "Time":
            return "Zeit [h]";
        case "Flow":
            return "Fluss [m³/h]";
        case "Volume":
            return "Volumen [m³]";
        case "Voltage":
            return "Elektrische Spannung [V]";
        case "Current":
            return "Stromstärke [A]";
        case "TemperatureDifferent":
            return "Temperaturgefälle [K / ℃]";
        default:
            return "";
    }
}

export function utcToLocal(date) {
    return new Date(Date.parse(date) - new Date().getTimezoneOffset() * 60000);
}

export function localToUTC(date) {
    return new Date(Date.parse(date) + new Date().getTimezoneOffset() * 60000);
}

export function dateToString(date) {
    //TODO this should be done with proper formatting strings perhaps
    return date.toLocaleString().replace(",", "");
}

export function exportMeasurementsToCSVDownload(jsonData) {
    let dataStr = ConvertToCSV(jsonData);
    exportToCSVDownload("data", dataStr);
}

export function CSVtoJSONConverter(rawString) {
    let arr = rawString.split('\n');
    var jsonObj = [];
    var headers = arr[0].split(';');
    for (var i = 1; i < arr.length; i++) {
        var data = arr[i].split(';');
        var obj = {};
        for (var j = 0; j < data.length; j++) {
            obj[headers[j].trim()] = data[j].trim();
        }
        if (!(data.length == 1 && data[0].length == 0)) jsonObj.push(obj);
    }
    return jsonObj;
}

export function exportToCSVDownload(name = "", dataStr = "") {
    let dataUri = 'data:application/csv;charset=utf-8,' + encodeURIComponent(dataStr);
    let exportFileDefaultName = name + '.csv';
    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
}

export function clamp(val, min, max) {
    return val > max ? max : val < min ? min : val;
}


// rotates x,y around a center cx, cy
export function rotatePoint(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) - (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) + (sin * (x - cx)) + cy;
    return [nx, ny];
}

function ConvertToCSV(objArray) {
    var seperator = ';';
    var str_timestamp = "t";
    var str_value = "avg";

    var data = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    //Header
    var line = 'Timestamp';
    var timestamps = {};

    for (var k = 0; k < data.length; k++) {
        var index = Object.keys(data)[k];
        if (line != '') line += seperator;
        line += data[index]["measurement"]["name"];
        var entries = data[index]["entries"];

        for (var i = 0; i < entries.length; i++) {
            if (timestamps[entries[i][str_timestamp]] == null) {
                timestamps[entries[i][str_timestamp]] = generateEmptyArray(k);
            }
            timestamps[entries[i][str_timestamp]].push(entries[i][str_value]);

        }
        timestamps = fillNulls(timestamps, k);
    }
    str += line + '\r\n';
    for (const [key, val] of Object.entries(timestamps)) {
        var line = "";
        var dat = new Date(Date.parse(key) - new Date().getTimezoneOffset() * 60000);
        line += dat.getFullYear() + '/' + String(dat.getMonth() + 1) + '/' + dat.getDate() + ' ' + (String(dat.getHours()).padStart(2, '0')) + ':' + (String(dat.getMinutes()).padStart(2, '0')) + ':' + (String(dat.getSeconds()).padStart(2, '0')) + seperator;
        for (var ele in val) if (val[ele] != null) {
            line += new Intl.NumberFormat('de-DE').format(val[ele]) + seperator;
        } else {
            line += '' + seperator;
        }
        str += line + '\r\n';

    }
    return str;
}

function generateEmptyArray(count) {
    var r = [];
    for (var j = 0; j < count; j++) {
        r.push(null);
    }
    return r;
}

function fillNulls(timestamps, len) {
    for (const [key, val] of Object.entries(timestamps)) {
        if (val.length != (len + 1)) val.push(null);
    }
    return timestamps
}

export const useForceUpdate = () => {
    const [x, set] = useState(0);
    return () => set(x => x + 1);
}

export function chartTimeUnit(interval) {
    let timeUnit = 'hour';
    let count = 1;
    if (interval === "MINUTELY" || interval === "QUARTERHOURLY") {
        timeUnit = 'minute';
        if (interval === "QUARTERHOURLY") count = 15;
    } else if (interval === 'DAILY') {
        timeUnit = 'day'
    } else if (interval === 'YEARLY') {
        timeUnit = 'year';
    }
    return {timeUnit: timeUnit, count: count};
}

export function isUUID(uuid) {
    let s = "" + uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return s !== null;
}

/**
 * Check if a profile json string (returned by GET or POST v2/login) is valid.
 * If invalid, return null. If valid, return the object.
 * @param profile
 * @returns {null|{validUntil}|{token}|any}
 */
export function checkProfile(profile) {
    if (!profile) return null;
    try {
        if (!isUUID(profile.user.guid) || !(profile.token) || !(profile.validUntil)) return null;
        if (profile.validUntil < new Date()) return null;
        return profile;
    } catch (e) {
        console.error("Could not parse", profile);
        return null;
    }
}

/**
 * If Local Storage contains data from the current app version, do nothing.
 * Otherwise, delete all data in Local Storage.
 */
export function verifyLocalStorage() {
    const APP_VERSION = localStorageVersion;
    if (localStorage.APP_VERSION !== APP_VERSION) {
        localStorage.clear();
        localStorage.setItem('APP_VERSION', APP_VERSION);
        console.log("clearing local storage");
    }
}

export function getSavedProfile() {
    verifyLocalStorage();
    const str_json = localStorage.getItem('Profile');
    let profile;
    try {
        profile = checkProfile(JSON.parse(str_json));
    } catch (e) {
        console.error("Error parsing Profile", str_json);
        profile = null;
    }

    //If exported value is null, whatever is in Profile is invalid, so it must be deleted.
    //Note that overwriting null with null is harmless.
    console.log('localStorage[Profile]=', null)
    if (!profile) localStorage.setItem('Profile', null);
    return profile;
}