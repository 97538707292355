import React from 'react';
import { LoginFooter, TextFooter, ULFooter, LIFooter, LinkFooter } from '../style/StyledContainer';
import { useTranslation } from 'react-i18next';

function Footer() {
    const year = new Date().getFullYear();
    const { t, i18n } = useTranslation();
    return (
        <LoginFooter>
            <TextFooter>© {year} Sigmaheat</TextFooter>
            <ULFooter>
                <LIFooter>
                    <LinkFooter href="https://sigmaheat.de/datenschutz">{t("privacy")}</LinkFooter>
                </LIFooter>
                <LIFooter>
                    <LinkFooter href="https://sigmaheat.de/impressum">{t("imprint")}</LinkFooter>
                </LIFooter>
            </ULFooter>
        </LoginFooter>
    )
}

export default Footer