/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class LogType.
* @enum {}
* @readonly
*/
export default class LogType {
    
        /**
         * value: "COMMENT"
         * @const
         */
        "COMMENT" = "COMMENT";

    
        /**
         * value: "OPTIMIZATION_SUGGESTION"
         * @const
         */
        "OPTIMIZATION_SUGGESTION" = "OPTIMIZATION_SUGGESTION";

    
        /**
         * value: "STATUS"
         * @const
         */
        "STATUS" = "STATUS";

    
        /**
         * value: "LOG"
         * @const
         */
        "LOG" = "LOG";

    
        /**
         * value: "EVENT"
         * @const
         */
        "EVENT" = "EVENT";

    
        /**
         * value: "OBJECT_EVENT"
         * @const
         */
        "OBJECT_EVENT" = "OBJECT_EVENT";

    
        /**
         * value: "MAINTENANCE_BOOK"
         * @const
         */
        "MAINTENANCE_BOOK" = "MAINTENANCE_BOOK";

    
        /**
         * value: "OTHER"
         * @const
         */
        "OTHER" = "OTHER";

    
        /**
         * value: "COUNTER_RESET"
         * @const
         */
        "COUNTER_RESET" = "COUNTER_RESET";

    

    /**
    * Returns a <code>LogType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/LogType} The enum <code>LogType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

