import React, { useState, useContext, useEffect } from 'react'
import { LoginContext } from "../Context"
import { Table } from 'react-bootstrap'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { utcToLocal, dateToString } from "../util/helpers"
import { MaintenanceBookTrigger } from '../plants/MaintenanceBookModal'
import {faBookOpen} from "@fortawesome/free-solid-svg-icons/faBookOpen";
import { useTranslation } from 'react-i18next'

function MaintenanceBook(props) {
    const [login, setLogin, api] = useContext(LoginContext);
    const maintenanceBookList = props.maintenanceBookList;
    const { t, i18n } = useTranslation();

    const TableRow = (info) => {
        const [display, setDisplay] = useState(false);
        console.log("name",info.body.name);
        return (
            <>
                <tr className="align-middle">
                    <td><MaintenanceBookTrigger {...{icon: faBookOpen, log: info, step: 2, reload: props.reload}} /></td>
                    <td>{info.body.name || t("otherEntry")}</td>
                    <td>{info.uploader.name}</td>
                    <td>{dateToString(utcToLocal(info.occuredAt))}</td>
                </tr>
            </>
        );
    };

    return (
        <Table responsive className="table-centered table-nowrap rounded mb-0 table-striped">
            <thead className="thead-light">
                <tr>
                    <th className="border-0 min-td"><FontAwesomeIcon icon={faList} size="sm" /></th>
                    <th className="border-0 min-td">{t("title")}</th>
                    <th className="border-0 min-td">{t("author")}</th>
                    <th className="border-0 min-td">{t("createdOn")}</th>
                </tr>
            </thead>
            <tbody>
                {maintenanceBookList.length != 0 && maintenanceBookList.map((info) => <TableRow key={`maintenancebook-${info.logGuid}-${info.occuredAt}`} {...info} />)}
            </tbody>
        </Table>
    )
}

export default MaintenanceBook