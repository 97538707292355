/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import EventOut from '../model/EventOut';

/**
* V2CustomerGuidEvents service.
* @module api/V2CustomerGuidEventsApi
* @version v2
*/
export default class V2CustomerGuidEventsApi {

    /**
    * Constructs a new V2CustomerGuidEventsApi. 
    * @alias module:api/V2CustomerGuidEventsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2CustomerGuidEventsGet operation.
     * @callback module:api/V2CustomerGuidEventsApi~v2CustomerGuidEventsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/EventOut>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit  (default to 2147483647)
     * @param {Number} opts.offset  (default to 0)
     * @param {module:api/V2CustomerGuidEventsApi~v2CustomerGuidEventsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/EventOut>}
     */
    v2CustomerGuidEventsGet(guid, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling v2CustomerGuidEventsGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [EventOut];
      return this.apiClient.callApi(
        '/v2/customer/{guid}/events', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
