import React, { useState } from 'react';
import { Card, Col, Row, Alert, Form } from 'react-bootstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SHButton } from '../style/SigmaHeatTheme';
import { useTranslation } from "react-i18next";

export const PasswordForm = (props) => {
    const { t, i18n } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [showSecondPassword, setShowSecondPassword] = useState(false);
    const [password, setPassword] = useState(props.password);
    const [secondPassword, setSecondPassword] = useState(props.password);
    const initialMsg = t("passwordInitialMsg");
    const [message, setMessage] = useState(initialMsg);
    const [msgVariant, setMsgVariant] = useState('secondary');
    const onSubmit = props.onSubmit;

    const execute = () => {
        if (password == secondPassword) {
            onSubmit(password);
        } else {
            setMsgVariant('danger');
            setMessage(t("matchPasswords"));
        }
    }

    return (
        <Card border="light" className="bg-white shadow-sm">
            <Card.Body>
                <Alert variant={msgVariant}>{message}</Alert>
                <Form
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    onSubmit={(e) => {e.preventDefault(); execute();}}>
                    <Form.Group id="password" className="mb-3">
                        <Form.Label>{t("newPassword")}:</Form.Label>
                        <div style={{display: 'grid', gridTemplateColumns: 'calc(100% - 58px) 42px', gridColumnGap: '1rem'}}>
                            <Form.Control required type={showPassword ? "text" : "password"} value={password || ''} onChange={(e) => {setPassword(e.target.value)}} />
                            <SHButton className="outline" onClick={() => {setShowPassword(!showPassword)}}>
                                <FontAwesomeIcon icon={faEye} size="sm" />
                            </SHButton>
                        </div>
                    </Form.Group>
                    <Form.Group id="password" className="mb-3">
                        <Form.Label>{t("newPassword")} ({t("repeat")}):</Form.Label>
                        <div style={{display: 'grid', gridTemplateColumns: 'calc(100% - 58px) 42px', gridColumnGap: '1rem'}}>
                            <Form.Control required type={showSecondPassword ? "text" : "password"} value={secondPassword || ''} onChange={(e) => {setSecondPassword(e.target.value)}} />
                            <SHButton className="outline" onClick={() => {setShowSecondPassword(!showSecondPassword)}} variant="outline-primary">
                                <FontAwesomeIcon icon={faEye} size="sm" />
                            </SHButton>
                        </div>
                    </Form.Group>
                    <Row>
                        <Col md={4}>
                            <SHButton type="submit">{t("apply")}</SHButton>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
};