/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CustomerOut from '../model/CustomerOut';
import CustomerPatch from '../model/CustomerPatch';

/**
* V2CustomerGuid service.
* @module api/V2CustomerGuidApi
* @version v2
*/
export default class V2CustomerGuidApi {

    /**
    * Constructs a new V2CustomerGuidApi. 
    * @alias module:api/V2CustomerGuidApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the v2CustomerGuidDelete operation.
     * @callback module:api/V2CustomerGuidApi~v2CustomerGuidDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/V2CustomerGuidApi~v2CustomerGuidDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    v2CustomerGuidDelete(guid, callback) {
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling v2CustomerGuidDelete");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v2/customer/{guid}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the v2CustomerGuidGet operation.
     * @callback module:api/V2CustomerGuidApi~v2CustomerGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/V2CustomerGuidApi~v2CustomerGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerOut}
     */
    v2CustomerGuidGet(guid, callback) {
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling v2CustomerGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerOut;
      return this.apiClient.callApi(
        '/v2/customer/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the v2CustomerGuidPatch operation.
     * @callback module:api/V2CustomerGuidApi~v2CustomerGuidPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerOut} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerPatch} opts.customerPatch 
     * @param {module:api/V2CustomerGuidApi~v2CustomerGuidPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerOut}
     */
    v2CustomerGuidPatch(guid, opts, callback) {
      opts = opts || {};
      let postBody = opts['customerPatch'];
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling v2CustomerGuidPatch");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerOut;
      return this.apiClient.callApi(
        '/v2/customer/{guid}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
