/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Address from './Address';
import GeographicCoordinates from './GeographicCoordinates';

/**
 * The BuildingOutWithExtras model module.
 * @module model/BuildingOutWithExtras
 * @version v2
 */
class BuildingOutWithExtras {
    /**
     * Constructs a new <code>BuildingOutWithExtras</code>.
     * @alias module:model/BuildingOutWithExtras
     */
    constructor() { 
        
        BuildingOutWithExtras.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BuildingOutWithExtras</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BuildingOutWithExtras} obj Optional instance to populate.
     * @return {module:model/BuildingOutWithExtras} The populated <code>BuildingOutWithExtras</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BuildingOutWithExtras();

            if (data.hasOwnProperty('guid')) {
                obj['guid'] = ApiClient.convertToType(data['guid'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = Address.constructFromObject(data['address']);
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = GeographicCoordinates.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('property')) {
                obj['property'] = ApiClient.convertToType(data['property'], 'String');
            }
            if (data.hasOwnProperty('plantCount')) {
                obj['plantCount'] = ApiClient.convertToType(data['plantCount'], 'Number');
            }
            if (data.hasOwnProperty('activeAlertCount')) {
                obj['activeAlertCount'] = ApiClient.convertToType(data['activeAlertCount'], 'Number');
            }
            if (data.hasOwnProperty('openAlertCount')) {
                obj['openAlertCount'] = ApiClient.convertToType(data['openAlertCount'], 'Number');
            }
            if (data.hasOwnProperty('meta')) {
                obj['meta'] = ApiClient.convertToType(data['meta'], {'String': Object});
            }
        }
        return obj;
    }


}

/**
 * @member {String} guid
 */
BuildingOutWithExtras.prototype['guid'] = undefined;

/**
 * @member {String} name
 */
BuildingOutWithExtras.prototype['name'] = undefined;

/**
 * @member {module:model/Address} address
 */
BuildingOutWithExtras.prototype['address'] = undefined;

/**
 * @member {String} comment
 */
BuildingOutWithExtras.prototype['comment'] = undefined;

/**
 * @member {module:model/GeographicCoordinates} location
 */
BuildingOutWithExtras.prototype['location'] = undefined;

/**
 * @member {String} property
 */
BuildingOutWithExtras.prototype['property'] = undefined;

/**
 * @member {Number} plantCount
 */
BuildingOutWithExtras.prototype['plantCount'] = undefined;

/**
 * @member {Number} activeAlertCount
 */
BuildingOutWithExtras.prototype['activeAlertCount'] = undefined;

/**
 * @member {Number} openAlertCount
 */
BuildingOutWithExtras.prototype['openAlertCount'] = undefined;

/**
 * @member {Object.<String, Object>} meta
 */
BuildingOutWithExtras.prototype['meta'] = undefined;






export default BuildingOutWithExtras;

