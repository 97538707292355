/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OwnedObjectType from './OwnedObjectType';

/**
 * The AccessCodeIn model module.
 * @module model/AccessCodeIn
 * @version v2
 */
class AccessCodeIn {
    /**
     * Constructs a new <code>AccessCodeIn</code>.
     * @alias module:model/AccessCodeIn
     */
    constructor() { 
        
        AccessCodeIn.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccessCodeIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccessCodeIn} obj Optional instance to populate.
     * @return {module:model/AccessCodeIn} The populated <code>AccessCodeIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccessCodeIn();

            if (data.hasOwnProperty('ownedObject')) {
                obj['ownedObject'] = ApiClient.convertToType(data['ownedObject'], 'String');
            }
            if (data.hasOwnProperty('ownedObjectType')) {
                obj['ownedObjectType'] = OwnedObjectType.constructFromObject(data['ownedObjectType']);
            }
            if (data.hasOwnProperty('validUntil')) {
                obj['validUntil'] = ApiClient.convertToType(data['validUntil'], 'Date');
            }
            if (data.hasOwnProperty('allowWrite')) {
                obj['allowWrite'] = ApiClient.convertToType(data['allowWrite'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} ownedObject
 */
AccessCodeIn.prototype['ownedObject'] = undefined;

/**
 * @member {module:model/OwnedObjectType} ownedObjectType
 */
AccessCodeIn.prototype['ownedObjectType'] = undefined;

/**
 * @member {Date} validUntil
 */
AccessCodeIn.prototype['validUntil'] = undefined;

/**
 * @member {Boolean} allowWrite
 */
AccessCodeIn.prototype['allowWrite'] = undefined;






export default AccessCodeIn;

