/**
 * SigmaHeat Backend API v2
 * The Backend API for SigmaHeat; Now with 900% more GUIDs
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RxInfo model module.
 * @module model/RxInfo
 * @version v2
 */
class RxInfo {
    /**
     * Constructs a new <code>RxInfo</code>.
     * @alias module:model/RxInfo
     */
    constructor() { 
        
        RxInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>RxInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RxInfo} obj Optional instance to populate.
     * @return {module:model/RxInfo} The populated <code>RxInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RxInfo();

            if (data.hasOwnProperty('gatewayID')) {
                obj['gatewayID'] = ApiClient.convertToType(data['gatewayID'], 'String');
            }
            if (data.hasOwnProperty('time')) {
                obj['time'] = ApiClient.convertToType(data['time'], 'Date');
            }
            if (data.hasOwnProperty('rssi')) {
                obj['rssi'] = ApiClient.convertToType(data['rssi'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} gatewayID
 */
RxInfo.prototype['gatewayID'] = undefined;

/**
 * @member {Date} time
 */
RxInfo.prototype['time'] = undefined;

/**
 * @member {Number} rssi
 */
RxInfo.prototype['rssi'] = undefined;






export default RxInfo;

